<template>
  <b-container class="text-center mt-5">
    <b-row v-if="error">
      <b-col cols="12"><h1>Leider ist etwas schief gelaufen!</h1></b-col>
      <b-col cols="12">
        <b-icon icon="x-circle" variant="danger" font-scale="3" class="my-5"></b-icon>
      </b-col>
      <b-col cols="12"
        >Leider kann Ihre Buchung derzeit nicht angezeigt werden. D.h. nicht, dass die Buchung nicht erfolgt ist. Wenn sie eine Email bekommen haben, ist alles in Ordnung. Sollten
        Sie keine Buchungsbestätigung erhalten haben, wenden Sie sich bitte per Email an parken <a href="mailto:parken-airpark@gmx.de">parken-airpark@gmx.de</a>.</b-col
      >
    </b-row>
    <b-row v-else-if="order === undefined">
      <b-col cols="12"><h1>Buchung wird bearbeitet</h1></b-col>
      <b-col cols="12" class="my-5"><b-spinner type="grow" label="Ihre Rechnung wird erzeugt..."></b-spinner></b-col>
      <b-col cols="12">Ihre Rechnung wird erzeugt und verschickt...</b-col>
    </b-row>
    <b-row v-else-if="order && order.payment === 'deleted'">
      <b-col cols="12" class="my-5">
        <b-icon icon="exclamation-circle-fill" variant="danger" font-scale="3"></b-icon>
      </b-col>
      <b-col cols="12"><h1>Stornierte Bestellung</h1></b-col>
      <b-col cols="12">Diese Bestellung wurde storniert!</b-col>
    </b-row>
    <b-row v-else-if="order && order.payment !== 'paid'">
      <b-col cols="12" class="my-5">
        <b-icon icon="check2-circle" variant="success" font-scale="3"></b-icon>
      </b-col>
      <b-col cols="12"><h1>Reserviert</h1></b-col>
      <b-col cols="12"
        >Deine Parkplätze wurden erfolgreich reserviert!<br /><br /><strong
          >Bei der von dir gewählten Zahlungsmethode kann es aber bis zu einer Woche dauern, bis die Zahlung bestätigt wurde. Erst dann erhältst du auch deine Email mitsamt der
          Rechnung.</strong
        ></b-col
      >
    </b-row>
    <b-row v-else>
      <b-row>
        <b-col cols="12"><h1>Buchung erfolgreich!</h1></b-col>
        <b-col cols="12" class="my-5">
          <b-icon icon="check2-circle" variant="success" font-scale="3"></b-icon>
        </b-col>
        <b-col cols="12" class="my-5"
          ><strong>
            Buchung von {{ order.cars.length }} Fahrzeug(en) für den Zeitraum&nbsp; {{ new Date(order.from).toLocaleString('de', dateOptions) }} bis
            {{ new Date(order.to).toLocaleString('de', dateOptions) }} </strong
          ><br
        /></b-col>
        <b-col cols="12" class="mt-3">
          <b-list-group id="overview-list" class="mb-3">
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>ID:</div>
              <div>
                {{ order.id }}
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>{{ ui.gebuchteTage }}:</div>
              <div>
                {{ order.bookedDays }}
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>{{ ui.anreiseTag }}:</div>
              <div>
                {{ new Date(order.from).toLocaleString('de', dateOptions) }}
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>{{ ui.abreiseTag }}:</div>
              <div>
                {{ new Date(order.to).toLocaleString('de', dateOptions) }}
              </div>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>
                {{ ui.gutschein }}
              </div>
              <div>
                {{ order.voucher ? 'Ja' : 'Nein' }}
              </div>
            </b-list-group-item>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>
                {{ ui.anzahlKfz }}
              </div>
              <div>
                {{ order.cars.length }}
              </div>
            </b-list-group-item>

            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <div>
                <strong>{{ ui.kennzeichen }}</strong>
              </div>
              <div>
                <strong>{{ order.cars.join(', ') }}</strong>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="12" v-if="!immediate" class="mt-3">
          <strong>
            In den nächsten Minuten erhalten Sie Ihre Bestellbestätigung samt Rechnung per Email. Bitte prüfen Sie auch den Spamfilter. Sollten Sie keine Email erhalten haben,
            wenden Sie sich bitte mit der Buchungsnummer {{ id }} an <a href="mailto:parken-airpark@gmx.de">parken-airpark@gmx.de</a>.</strong
          >
        </b-col>
      </b-row>
    </b-row>
  </b-container>
</template>

<script>
const baseUrl = process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_STAGE + '/'
import axios from 'axios'
export default {
  name: 'Success',
  data() {
    return { order: undefined, error: undefined, retries: 0, id: '', immediate: false, timeoutHandle: 0, dateOptions: { year: 'numeric', month: '2-digit', day: '2-digit' } }
  },
  created() {
    this.immediate = 'immediate' in this.$route.query && this.$route.query.immediate
    const timeout = this.immediate ? 0 : 10000
    console.log('fetching order in 10 seconds')
    this.timeoutHandle = setTimeout(() => this.fetchOrder(), timeout)
  },
  computed: {
    ui: function () {
      return this.$store.state.ui
    }
  },
  methods: {
    fetchOrder: async function () {
      clearTimeout(this.timeoutHandle)
      this.id = this.$route.params.id
      console.log('getting order with id ' + this.id)
      try {
        const req = await axios.get(`${baseUrl}order/${this.id}`)
        console.log('got order', req.data)
        this.order = req.data
      } catch (error) {
        if (error) {
          this.retries++
          if (this.retries > 2 || this.immediate) {
            this.error = error
            return
          } else {
            this.timeoutHandle = setTimeout(() => this.fetchOrder(), 10000)
            return
          }
        }
      }
    }
  }
}
</script>

<style>
h1 {
  font-weight: 900 !important;
}
</style>