<template>
  <div class="home">
    <b-jumbotron class="m-0">
      <template #header>{{ ui.header }}</template>

      <template #lead>
        {{ ui.urlaub }}
      </template>

      <hr class="my-4" />
      <b-container>
        <b-row>
          <b-col cols="12">
            <static-info />

            <h3 class="text-left my-3" id="buchung">{{ ui.buchung }}</h3>
            <hr class="my-4 mx-3" />
            <b-container>
              <b-row class="my-5 text-left">
                <b-col cols="12" class="mb-3 text-center">
                  <b-alert show v-if="matrix.length > 0 && bookedDays > matrix[matrix.length - 1].end">
                    {{ ui.leider }}{{ bookedDays }}{{ ui.buchungstagen }} {{ matrix[matrix.length - 1].end }}{{ ui.tagen }}
                  </b-alert>
                  <b-alert show v-else-if="unavailableDates && unavailableDates.length > 0" variant="warning">
                    {{ ui.anFolgendenTagen }}
                    {{
                      unavailableDates
                        .map((d) => new Date(d.date).toLocaleString('de', { year: 'numeric', month: '2-digit', day: '2-digit' }) + ' (' + d.capacity + ' verfügbar)')
                        .join(', ')
                    }}.
                  </b-alert>
                  <b-alert show variant="success" v-else>
                    <span v-if="isCheckingAvailability"> <b-spinner small grow variant="success" label="Spinning" class="mr-3"></b-spinner>{{ ui.pruefe }} </span>
                    <span v-else>
                      {{ ui.genug }}
                    </span>
                  </b-alert>
                </b-col>
                <b-col sm="4">
                  <div class="ml-3 advantage-header"><span class="advantage-number mr-2">1.</span></div>
                  <label for="startDatePicker" class="mt-3"
                    ><small>
                      <div v-if="isStartDateValid">
                        <strong>{{ ui.anreise }}</strong>
                        {{ new Date(startDate).toLocaleString('de', { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }) }}
                      </div>
                      <div v-else>
                        <b-icon icon="exclamation-triangle-fill" variant="warning" class="mr-2"></b-icon>
                        <em>{{ ui.inZukunft }}</em>
                      </div>
                    </small></label
                  >
                  <b-form-datepicker
                    class="mb-3"
                    id="startDatePicker"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :placeholder="ui.anreiseTag"
                    :locale="selectedLanguage"
                    start-weekday="Monday"
                    :state="isStartDateValid ? null : false"
                    v-model="startDate"
                    value-as-date
                  >
                  </b-form-datepicker>
                  <label for="endDatePicker" class="mt-3"
                    ><small>
                      <div v-if="isEndDateValid">
                        <strong>{{ ui.abreise }}</strong>
                        {{ new Date(endDate).toLocaleString('de', { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }) }}
                      </div>
                      <div v-else>
                        <b-icon icon="exclamation-triangle-fill" variant="warning" class="mr-2"></b-icon>
                        <em>{{ ui.abreiseDanach }}</em>
                      </div>
                    </small></label
                  >
                  <b-form-datepicker
                    class="mb-3"
                    id="endDatePicker"
                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                    :placeholder="ui.abreisetag"
                    :locale="selectedLanguage"
                    start-weekday="Monday"
                    :state="isEndDateValid ? null : false"
                    v-model="endDate"
                    value-as-date
                  ></b-form-datepicker>

                  <span v-for="(car, index) in cars" v-bind:key="index">
                    <label :for="'carInput-' + index" class="mt-3"
                      ><small>
                        <div>{{ ui.kennzeichen }} {{ index + 1 }}. {{ ui.auto }}</div>
                      </small></label
                    >
                    <b-input-group class="mb-3">
                      <b-form-input :id="'carInput-' + index" :placeholder="ui.kennzeichen" type="text" v-model="cars[index]"> </b-form-input>
                      <b-input-group-append v-if="index >= 1">
                        <b-button variant="outline-danger" @click="removeCar(index)">
                          <b-icon icon="x"></b-icon>
                        </b-button>
                      </b-input-group-append>
                      <b-input-group-append v-if="index === cars.length - 1 && cars.length < 3">
                        <b-button variant="outline-success" @click="addCar">
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </span>
                </b-col>
                <b-col sm="4">
                  <div class="ml-3 advantage-header"><span class="advantage-number mr-2">2.</span>{{ ui.kontaktDaten }}</div>
                  <label for="nameInput" class="mt-3"
                    ><small>
                      <div>{{ ui.name }}</div>
                    </small></label
                  >
                  <b-form-input id="nameInput" :placeholder="ui.name" v-model="name" type="text" :state="isNameValid ? true : undefined" class="mb-3"></b-form-input>
                  <label for="mailInput" class="mt-3"
                    ><small>
                      <div>{{ ui.email }}</div>
                    </small></label
                  >
                  <b-form-input id="mailInput" :placeholder="ui.email" type="email" v-model="mail" :state="isMailValid ? true : undefined" class="mb-3"></b-form-input>
                  <label for="phoneInput" class="mt-3"
                    ><small>
                      <div>{{ ui.telefon }}</div>
                    </small></label
                  >
                  <b-form-input
                    id="phoneInput"
                    :placeholder="ui.telefon"
                    type="tel"
                    v-model="phone"
                    :state="isPhoneValid ? true : undefined"
                    class="mb-3"
                    lazy-formatter
                    :formatter="phoneFormatter"
                  >
                  </b-form-input>
                  <label for="streetInput" class="mt-3"
                    ><small>
                      <div>{{ ui.rechnungsadresse }}</div>
                    </small></label
                  >
                  <b-form-input id="companyInput" :placeholder="ui.firma" type="text" v-model="company" :state="undefined" class="mb-3" autocomplete="off"></b-form-input>
                  <b-form-input id="streetInput" :placeholder="ui.strasse" type="text" v-model="street" :state="undefined" class="mb-3"></b-form-input>
                  <b-form-input id="townInput" :placeholder="ui.stadt" type="email" v-model="town" :state="undefined" class="mb-3"></b-form-input>
                </b-col>
                <b-col sm="4">
                  <div class="ml-3 advantage-header"><span class="advantage-number mr-2">3.</span>{{ ui.gebuehren }}</div>
                  <label for="priceChart" class="mt-3"
                    ><small>
                      <div>{{ ui.preisliste }}:</div>
                    </small></label
                  >
                  <b-button block v-b-toggle.accordion-1 variant="secondary" v-if="matrix.length > 0">1 - {{ firstHalfMatrix[firstHalfMatrix.length - 1].end }} Tage</b-button>
                  <b-collapse id="accordion-1" visible accordion="price-accordion" role="tabpanel" v-if="matrix">
                    <b-list-group class="mb-3" id="priceChart" v-if="matrix.length > 0">
                      <b-list-group-item
                        v-for="(entry, index) in firstHalfMatrix"
                        v-bind:key="entry.price"
                        :variant="index === selectedPriceIndex ? 'success' : undefined"
                        class="py-1"
                        v-bind:class="{ 'font-weight-bold': index === selectedPriceIndex }"
                      >
                        {{ entry.start }} {{ ui.bis }} {{ entry.end }} {{ ui.tage }}:
                        {{ (entry.price / 100).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                  <b-button block v-b-toggle.accordion-2 variant="secondary" v-if="matrix.length > 0">
                    {{ secondHalfMatrix[0].start }} - {{ secondHalfMatrix[secondHalfMatrix.length - 1].end }} {{ ui.tage }}</b-button
                  >
                  <b-collapse id="accordion-2" accordion="price-accordion" role="tabpanel" v-if="matrix">
                    <b-list-group class="mb-3" id="priceChart" v-if="matrix.length > 0">
                      <b-list-group-item
                        v-for="(entry, index) in secondHalfMatrix"
                        v-bind:key="entry.price"
                        :variant="index + firstHalfMatrix.length === selectedPriceIndex ? 'success' : undefined"
                        class="py-1"
                        v-bind:class="{ 'font-weight-bold': index + firstHalfMatrix.length === selectedPriceIndex }"
                      >
                        {{ entry.start }} {{ ui.bis }} {{ entry.end }} {{ ui.tage }}:
                        {{ (entry.price / 100).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}
                      </b-list-group-item>
                    </b-list-group>
                  </b-collapse>
                </b-col>
                <b-col cols="12">
                  <div class="ml-3 advantage-header"><span class="advantage-number mr-2">4.</span>{{ ui.zahlung }}</div>
                </b-col>
                <b-col sm="6">
                  <label for="overview-list" class="mt-3"
                    ><small>
                      <div>{{ ui.zusammenfassung }}</div>
                    </small></label
                  >
                  <b-list-group id="overview-list" class="mb-3">
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <div>{{ ui.gebuchteTage }}:</div>
                      <div>
                        {{ bookedDays }}
                      </div>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <div>
                        {{ ui.preisPro }}
                      </div>
                      <div>
                        {{ (pricePerCar / 100).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <div>
                        {{ ui.anzahlKfz }}
                      </div>
                      <div>
                        {{ numberOfCars }}
                      </div>
                    </b-list-group-item>
                    <b-list-group-item class="d-flex justify-content-between align-items-center" v-if="isVoucherValid">
                      <div>
                        {{ ui.gutschein }}
                      </div>
                      <div>
                        -
                        {{ (config.prices[bookedDays.toString()] / 100).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}
                      </div>
                    </b-list-group-item>

                    <b-list-group-item class="d-flex justify-content-between align-items-center">
                      <div>
                        <strong>{{ ui.gesamtpreis }}</strong>
                      </div>
                      <div>
                        <strong>{{ (price / 100).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}</strong>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col sm="6">
                  <label for="voucherInput" class="mt-3"
                    ><small>
                      <div>
                        {{ ui.gutschein }}
                        <span v-if="voucher"
                          >"{{ voucher.id }}" {{ ui.gueltigBis }} {{ voucher.to.toLocaleString('de', { year: 'numeric', month: '2-digit', day: '2-digit' }) }}</span
                        >
                      </div>
                    </small></label
                  >
                  <b-input-group class="mb-3">
                    <b-form-input id="voucherInput" :placeholder="ui.gutscheinCode" type="text" v-model="voucherCode" :state="isVoucherValid"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-success" @click="getVoucher" :disabled="voucherCode === '' || isBooking"> {{ ui.pruefen }} </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <b-checkbox v-model="termsAccepted" class="mt-3"
                    >{{ ui.agb1 }} <b-link href="agb">{{ ui.agb2 }} </b-link> {{ ui.agb3 }}.</b-checkbox
                  >
                  <div class="flex-fill"></div>
                  <b-button
                    class="mt-3"
                    block
                    variant="success"
                    @click="payNow"
                    :disabled="
                      !isBooking &&
                      !(
                        isMailValid &&
                        termsAccepted &&
                        isNameValid &&
                        isStartDateValid &&
                        isEndDateValid &&
                        numberOfCars > 0 &&
                        (!unavailableDates || unavailableDates.length === 0)
                      )
                    "
                    ><span v-if="isBooking"><b-spinner class="mx-2" small /></span>{{ ui.buchen.toUpperCase() }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
            <gallery />

            <h3 class="text-left my-3" id="anfahrt">{{ ui.anfahrt }}</h3>
            <hr class="my-4 mx-3" />
            <div class="my-2 text-left">
              <a href="https://goo.gl/maps/mo9aMrHmM7SmPHxg9">{{ ui.mitGoogle }}</a>
            </div>
            <div class="google-maps" id="map">
              <div v-if="!googleAccepted" class="w-100 mx-auto">
                {{ ui.googleCookie }}<br />
                <b-button @click="googleAccepted = true" variant="info" class="mt-5">{{ ui.zustimmen }}</b-button>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2628.7155096159563!2d8.113827116421852!3d48.78732127928053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796e1631b604ec9%3A0x2a3f08e3b42f8ee2!2sParkplatz!5e0!3m2!1sen!2sde!4v1619641271070!5m2!1sen!2sde"
                width="600"
                height="450"
                style="border: 0"
                v-if="googleAccepted"
                allowfullscreen=""
                loading="lazy"
                class="mb-3"
              ></iframe>
            </div>
            <h3 class="text-left mt-5 mb-3" id="kontakt">{{ ui.kontakt }}</h3>
            <hr class="my-4 mx-3" />
            <p class="text-left">{{ ui.dauer }}:</p>
            <b-container>
              <b-row class="my-5 text-left">
                <b-col sm="8">
                  <b-form-textarea id="messageInput" no-resize v-model="message" :placeholder="ui.nachricht" rows="8" max-rows="8"></b-form-textarea>
                </b-col>
                <b-col sm="4">
                  <label for="contactNameInput"
                    ><small>{{ ui.name }}</small></label
                  >
                  <b-form-input id="contactNameInput" :placeholder="ui.name" v-model="name" type="text" :state="isNameValid ? undefined : false" class="mb-2"></b-form-input>
                  <label for="contactMailInput" class="mt-2"
                    ><small>{{ ui.email }}</small></label
                  >
                  <b-form-input id="contactMailInput" :placeholder="ui.email" type="email" v-model="mail" :state="isMailValid ? undefined : false" class="mb-2"></b-form-input>
                  <div class="mt-3 text-right">
                    <b-button variant="primary" :disabled="!(isMailValid && isNameValid && message && message !== '')" @click="sendUserMail">{{ ui.abschicken }}</b-button>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-jumbotron>
    <!--<payment-modal :order="order" />-->
  </div>
</template>

<script>
import axios from 'axios'
import StaticInfo from '../components/StaticInfo.vue'
import Gallery from '../components/Gallery.vue'
//import PaymentModal from '../components/PaymentModal.vue'
import { mapGetters, mapMutations } from 'vuex'
const baseUrl = process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_STAGE + '/'
const now = new Date()
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
const dayDuration = 1000 * 60 * 60 * 24

const mailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/

export default {
  name: 'Home',
  components: {
    //PaymentModal,
    StaticInfo,
    Gallery
  },
  data() {
    return {
      loading: false,
      matrix: [],
      name: '',
      mail: '',
      phone: '',
      message: '',
      cars: [''],
      capacity: {},
      unavailableDates: undefined,
      isCheckingAvailability: false,
      termsAccepted: false,
      voucherCode: '',
      company: '',
      startDate: today,
      endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
      voucher: undefined,
      allSet: false,
      googleAccepted: false,
      town: '',
      street: '',
      stripe: undefined,
      order: undefined,
      isBooking: false
    }
  },
  watch: {
    startDate: function () {
      if (this.endDate < this.startDate) {
        this.endDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + 1)
      } else {
        this.getCapacity()
      }
    },
    endDate: function () {
      this.getCapacity()
    },
    cars: function () {
      this.validateCapacity()
    },
    config: function (newConfig) {
      if (!newConfig) return
      Object.keys(newConfig.prices).forEach((key) => {
        const price = newConfig.prices[key]
        const current = this.matrix.find((m) => m.price === price)
        if (current) {
          current.end = key
        } else {
          this.matrix.push({
            start: key,
            end: key,
            price: price
          })
        }
      })
      this.allSet = true
      this.validateCapacity()
    }
  },
  methods: {
    ...mapMutations(['setOrder']),
    getVoucher: async function () {
      this.voucher = undefined
      if (this.voucherCode === '') return
      this.isBooking = true
      try {
        const req = await axios.get(baseUrl + 'voucher/' + this.voucherCode)
        const voucher = req.data
        console.log('got voucher', voucher)
        voucher.from = new Date(voucher.from)
        voucher.to = new Date(voucher.to)

        if (voucher.to < new Date(this.endDate)) {
          this.toast(
            this.ui.gutscheinAbgelaufen,
            this.ui.gutscheinAbgelaufenText +
              this.voucher.to.toLocaleString('de', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              }),
            'danger'
          )
          return
        }

        if ('bookedDays' in voucher && this.startDate && this.endDate) {
          let dateToCheck = new Date(this.startDate)
          while (dateToCheck < this.endDate) {
            const dateString = `${dateToCheck.getFullYear()}-${(dateToCheck.getMonth() + 1).toString().padStart(2, '0')}-${dateToCheck.getDate().toString().padStart(2, '0')}`
            if (voucher.bookedDays.includes(dateString)) {
              this.toast(this.ui.gutscheinVerwendet, this.ui.gutscheinVerwendetText, 'danger')
              return
            }
            dateToCheck.setDate(dateToCheck.getDate() + 1)
          }
        }

        this.voucher = voucher

        this.toast(this.ui.gutscheinGueltig, this.ui.gutscheingueltigText, 'success')
      } catch (error) {
        console.error('error validating voucher', error)
        if (error.response && error.response.status === 404) {
          this.toast(this.ui.gutscheinNichtGefunden, this.ui.gutscheinNichtGefundenText, 'danger')
        }
      } finally {
        this.isBooking = false
      }
    },
    addCar: function () {
      this.cars.push('')
    },
    removeCar: function (index) {
      this.cars.splice(index, 1)
    },
    phoneFormatter: function (number) {
      return number.replace(/[^+0-9.]/g, '')
    },
    toast: function (title, message, variant) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 12000,
        variant: variant
      })
    },
    sendUserMail: async function () {
      const body = {
        name: this.name,
        message: this.message,
        mail: this.mail
      }
      try {
        await axios.post(baseUrl + 'mail', body)
      } catch (error) {
        console.error('error sending mail', error)
        this.toast(this.ui.nachrichtNichtGesendet, this.ui.nachrichtNichtGesendetText, 'danger')
        return
      }
      this.toast(this.ui.nachrichtGesendet, this.ui.nachrichtGesendetText, 'success')
    },
    getCapacity: async function () {
      if (!this.allSet || this.bookedDays > this.matrix[this.matrix.length - 1].end) {
        this.unavailableDates = undefined
        return
      }
      this.isCheckingAvailability = true
      const keys = Object.keys(this.capacity)
      const startDate = this.startDate
      const startDateString = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`
      const endDate = this.endDate
      const endDateString = `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`
      if (!(startDateString in keys) || !(endDateString in keys)) {
        try {
          const req = await axios.get(`${baseUrl}capacity/${startDateString}/${endDateString}`)
          this.capacity = req.data
          console.log('got capacity', this.capacity)
        } catch (error) {
          console.error('error getting capacity', error)
          this.unavailableDates = undefined
          return
        } finally {
          this.isCheckingAvailability = false
        }
      }
      this.validateCapacity()
    },
    validateCapacity: function () {
      if (this.capacity === undefined || !this.allSet) {
        console.warn('unable to validate capacity because we got no data')
        this.unavailableDates = undefined
        return
      }
      let date = this.startDate
      const endDate = this.endDate

      const unavailableDates = []
      while (date <= endDate) {
        const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
        const minNumberOfCars = this.numberOfCars > 0 ? this.numberOfCars : 1
        //console.log('checking availability for ' + minNumberOfCars + ' cars on ' + dateString)
        if (this.capacity[dateString] < minNumberOfCars)
          unavailableDates.push({
            date: dateString,
            capacity: this.capacity[dateString]
          })

        date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
      }
      if (unavailableDates.length > 0) {
        console.warn(this.ui.anFolgendenTagen, unavailableDates.map((d) => d.date + ' (' + d.capacity + ')').join(', '))
      }
      this.unavailableDates = unavailableDates
    },
    payNow: async function () {
      this.isBooking = true
      const o = {
        name: this.name,
        from: `${this.startDate.getFullYear()}-${(this.startDate.getMonth() + 1).toString().padStart(2, '0')}-${this.startDate.getDate().toString().padStart(2, '0')}`,
        to: `${this.endDate.getFullYear()}-${(this.endDate.getMonth() + 1).toString().padStart(2, '0')}-${this.endDate.getDate().toString().padStart(2, '0')}`,
        mail: this.mail,
        phone: this.phone,
        message: this.message,
        cars: this.cars,
        town: this.town,
        company: this.company,
        street: this.street,
        total: this.price,
        voucher: this.voucherCode
      }
      this.order = o
      this.setOrder(o)
      try {
        const paymentRequest = await axios.post(baseUrl + 'paymentIntent', o)
        console.log('payment intent created', paymentRequest.data)
        if ('payment' in paymentRequest.data && paymentRequest.data.payment === 'paid') {
          this.$router.push('/order/' + paymentRequest.data.id)
          return
        }
        this.stripe.redirectToCheckout({ sessionId: paymentRequest.data.sessionId })
      } catch (error) {
        console.error(error)
        this.$bvToast.toast(
          `Da ist wohl etwas schief gelaufen! Hast du einen ungültigen Gutscheincode eingegeben? Ansonsten versuch es bitte später nochmal, oder melde dich über unser Kontaktformular.`,
          {
            title: 'Fehler beim Buchen',
            autoHideDelay: 15000,
            variant: 'danger'
          }
        )
      } finally {
        this.isBooking = false
      }
    }
  },
  mounted() {
    this.stripe = Stripe(process.env.VUE_APP_STRIPE_PK) // eslint-disable-line no-undef
  },

  computed: {
    ...mapGetters(['config']),
    ui: function () {
      return this.$store.state.ui
    },
    isStartDateValid: function () {
      const startDate = this.startDate
      return startDate >= today
    },
    isVoucherValid: function () {
      if (!this.voucher || this.voucher === undefined) {
        // console.log('no voucher object')
        return undefined
      }
      const isValid = !(this.voucher.to < this.endDate)
      // console.log('comparing voucher date and end date', this.voucher.to, this.endDate, isValid)
      return isValid
    },
    isEndDateValid: function () {
      const endDate = this.endDate
      const startDate = this.startDate
      return endDate >= startDate && endDate >= today
    },
    isNameValid: function () {
      return this.name.length > 4
    },
    isMailValid: function () {
      const regexResult = mailRegex.test(this.mail)
      // console.log('mail is valid', regexResult)
      return regexResult
    },
    isPhoneValid: function () {
      return this.phone.length > 5 && (this.phone.startsWith('0') || this.phone.startsWith('+'))
    },
    validateAll: function () {
      const startDate = this.startDate
      const endDate = this.endDate

      const errors = {}

      errors.start =
        startDate >= today
          ? {
              valid: true
            }
          : {
              valid: false,
              reason: 'Ihre Anreise liegt vor dem heutigen Datum.'
            }
      errors.end =
        endDate >= startDate && endDate >= today
          ? {
              valid: true
            }
          : {
              valid: false,
              reason: 'Ihre Abreise liegt vor der Abreise.'
            }
      errors.name =
        this.name.length > 5
          ? {
              valid: true
            }
          : {
              valid: false,
              reason: 'Sie müssen Ihren Namen eintragen.'
            }
      errors.mail = mailRegex.test(this.mail)
        ? {
            valid: true
          }
        : {
            valid: false,
            reason: 'Wir benötigen Ihre Email-Adresse um Ihnen den Buchungsbeleg zu schicken.'
          }
      errors.phone =
        this.phone.length > 5 && (this.phone.startsWith('0') || this.phone.startsWith('+'))
          ? {
              valid: true
            }
          : {
              valid: false,
              reason: 'Wir benötigen Ihre Telefonnummer für Rückfragen.'
            }
      errors.car =
        this.numberOfCars > 0
          ? {
              valid: true
            }
          : {
              valid: false,
              reason: 'Bitte geben Sie Ihr KFZ-Kennzeichen an.'
            }
      errors.price = {
        valid: this.price > 0 || (this.price === 0 && this.isVoucherValid)
      }
      errors.order = {
        valid: errors.start.valid && errors.end.valid && errors.name.valid && errors.mail.valid && errors.phone.valid && errors.car.valid && errors.price.valid
      }
      return errors
    },
    bookedDays: function () {
      if (!this.allSet) return 0
      const startDate = this.startDate - 2 * 60 * 60 * 1000
      const endDate = this.endDate
      console.log('startDate', startDate)
      const bookedDays = Math.floor((endDate - startDate) / dayDuration) + 1
      // console.log('calculating booked days', startDate, endDate, bookedDays)
      return Math.max(0, bookedDays)
    },
    numberOfCars: function () {
      if (!this.allSet) return 0
      return this.cars.reduce(function (acc, cv) {
        if (cv !== '') acc++
        return acc
      }, 0)
    },
    selectedLanguage: function () {
      return this.$store.state.selectedLanguage
    },
    selectedPriceIndex: function () {
      if (!this.allSet) return 0
      if (this.matrix.length === 0) return 0
      return this.matrix.findIndex((p) => this.bookedDays >= p.start && this.bookedDays <= p.end)
    },
    pricePerCar: function () {
      if (!this.allSet) return 0
      if (!this.matrix || this.matrix === []) return 0
      if (this.bookedDays > this.matrix[this.matrix.length - 1].end) return 0
      if (this.bookedDays === 0) return 0
      return this.config.prices[this.bookedDays.toString()]
    },
    price: function () {
      return Math.max((this.numberOfCars - (this.isVoucherValid ? 1 : 0)) * this.pricePerCar, 0)
    },
    firstHalfMatrix: function () {
      return this.matrix.filter((_, i) => i <= this.matrix.length / 2)
    },
    secondHalfMatrix: function () {
      return this.matrix.filter((_, i) => i > this.matrix.length / 2)
    }
  }
}
</script>
<style>
.advantage-header {
  font-size: 1.25rem;
  font-weight: 700;
}

.advantage-number {
  font-weight: 900;
  font-size: 1.8rem;
  color: rgb(67 116 219 / 89%);
}

h3 {
  font-weight: 900 !important;
}
#map {
  height: 400px;
  /* The height is 400 pixels */
  width: 100%;
  /* The width is the width of the web page */
}
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
</style>