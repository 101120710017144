<template>
  <div>
    <b-container class="text-left">
      <b-row>
        <b-col>
          <h2 class="my-3">Allgemeine Geschäftsbedingungen</h2>
          <ol class="main">
            <li>
              <h5>Stornogebühren</h5>
              <p>
                Die Stornierung des Parkplatzes ist bis 24 Stunden vor dem vereinbarten Abholtermin ohne Angaben von Gründen in Textform (z.B. E-Mail oder Brief; Anschrift: Daniel
                Bechler Hardtwaldstr.1 76549 Hügelsheim, E-Mail Adresse parken-airparken@gmx.de) kostenfrei möglich, danach wird die Hälfte der Parkgebühr erhoben. Die Stornierung
                muss innerhalb von 24 Stunden vor dem vereinbarten Abholtermin eingegangen sein. Wurde bis 30 Minuten vor dem vereinbarten Abholtermin keine Stornierung erhalten,
                werden 80% der vereinbarten Parkgebühr fällig, falls eine anderweitige Vermietung nicht mehr möglich war. Dem Kunden bleibt der Nachweis gestattet, dass kein oder
                ein niedrigerer Schaden entstanden ist.
              </p>
            </li>
            <li>
              <h5>Haftungsausschluss</h5>
              <ol>
                <li>
                  guenstig-parken-Baden-Aipark.de übernimmt keine Haftung dafür, dass aus Fremdverschulden die tatsächliche Leistungserbringung vor Ort nicht zustande kommt. Dies
                  gilt ausdrücklich auch für eine Nichtinanspruchnahme der gebuchten Leistungen aufgrund von verspäteter Anreise des Kunden und für die Anreise mit übergroßen
                  Fahrzeugen (Fahrzeughöhe > 1,90m, Fahrzeugbreite > 2m, Fahrzeuglänge > 5,00m) ohne vorherige Rücksprache mit Billig-parken-Baden-Aipark.de oder für eine
                  Unmöglichkeit der Nutzung aus wichtigem, nicht vorhersehbarem Grund wie z.B. Beseitigung von Leitungsschäden oder anderer wichtiger Erdarbeiten oder wegen starker
                  Niederschläge und deren Folgen. Dies gilt auch für Wartezeiten die in seltenen Fällen entstehen können, wenn ein zugesagter Shuttledienst außerplanmäßige Fahrten
                  zwischen Flughafen und Parkplatz Fahrten durchführen muss.
                </li>
                <li>
                  guenstig-parken-Baden-Aipark.de haftet nicht für direkte oder indirekte Schäden, auch nicht für Druck- und Rechenfehler oder Fehler in der Datenübermittlung oder
                  Datenanzeige.
                </li>
                <li>
                  guenstig-parken-Baden-Aipark.de haftet für alle durch sie, ihre Angestellten oder Erfüllungsgehilfen schuldhaft verursachten Schäden auf Grund der gesetzlichen
                  Haftungsbestimmungen (Vorsatz, grobe Fahrlässigkeit). Jegliche weitere Haftung ist ausgeschlossen. Der Kunde stellt guenstig-parken-Baden-Aipark.de insoweit von
                  sämtlichen Ansprüchen Dritter frei. Bei leichter Fahrlässigkeit tritt eine Schadenshaftung nur dann ein, wenn diese auf Verletzung einer wesentlichen
                  Vertragspflicht in einer den Vertragszweck gefährdenden Weise zurückzuführen ist. In diesen Fällen ist die Haftung auf den vorhersehbaren, vertragstypischen
                  Schaden begrenzt. Ebenso stellt der Kunde guenstig-parken-Baden-Aipark.de frei bei Schäden höherer Gewalt sowie bei Schäden durch innere und äußere Unruhen,
                  Kriegsereignisse und elementare Naturkräfte.<br />Ein Ersatzanspruch des Kunden auf Minderung oder Schadenersatz entfällt dann, wenn dieser den Schaden zwecks
                  Schadensfeststellung nicht unverzüglich und noch vor Verlassen des Betriebsgeländes dem Aufsichtspersonal gemeldet hat.
                </li>
                <li>
                  Bei einfacher Fahrlässigkeit und für Buchungsfehler haftet guenstig-parken-Baden-Aipark.de nur und der Höhe nach begrenzt auf den Rechnungsumsatz, der unmittelbar
                  mit der betroffenen Reservierung unter gewöhnlichen Konditionen in Verbindung steht.
                </li>
                <li>
                  Für die Fälle der anfänglichen Unmöglichkeit haftet guenstig-parken-Baden-Aipark.de nur, wenn guenstig-parken-Baden-Airpark.de das Hindernis bekannt war oder die
                  Unkenntnis auf grober Fahrlässigkeit beruht. Die vorstehenden Haftungsbeschränkungen bzw. Ausschlüsse gelten nicht für Schäden aus der Verletzung des Lebens, des
                  Körpers und der Gesundheit. Insoweit die Haftung von guenstig-parken-Baden-Airpark.de ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche
                  Haftung der Angestellten, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen von guenstig-parken-Baden-Aipark.de.
                </li>
                <li>
                  Mit Ausnahme von Ansprüchen aus unerlaubter Handlung verjähren Schadensersatzansprüche, für die nach dieser Ziffer die Haftung beschränkt ist, sowie Ansprüche
                  wegen nicht vertragsgemäßer Erbringung der Leistungen in zwei Jahren.
                </li>
                <li>
                  guenstig-parken-Baden-Aipark.de schließt jegliche Haftung für Schäden aus, die durch Dritte oder andere Parkplatzmieter verursacht werden. Dies gilt insbesondere
                  für Beschädigung, Vernichtung oder Diebstahl des eingestellten Kraftfahrzeugs oder beweglicher / eingebauter Gegenstände aus dem Kraftfahrzeug (z.B. Autoradio,
                  Autotelefon, Handy oder persönliche Wertgegenstände, Fotoausrüstung, Navigationssystemen usw.) oder auf bzw. an dem Kraftfahrzeug befestigter Sachen. Bei Schäden
                  durch höhere Gewalt, innere Unruhen sowie äußere Unruhen, Kriegsereignisse, Elementarschäden, Marder- oder von anderen Tieren verursachte Schäden sowie
                  Immissionen Dritter ist guenstig-parken-Baden-Aipark.de vom Schadenersatz frei. guenstig-parken-Baden-Aipark.de übernimmt ebenso keine Haftung für Schäden die auf
                  dem Firmengelände oder im Verlauf des durchgeführten Shuttle-Service an Gepäckstücken des Kunden von diesen selbstverschuldet oder nicht selbstverschuldet
                  auftreten. Die pünktliche Ankunft am Flughafen, bezogen auf den konkreten Abflugtermin des Kunden, ist nicht Vertragsgegenstand. Schadenersatzansprüche,
                  insbesondere solche aus nicht erreichten Abflügen und sonstigen Terminen, sind ausgeschlossen. Es wird nicht garantiert, dass der guenstig-parken-Baden-Aipark.de
                  Shuttledienst das Terminal rechtzeitig erreicht.<br />Der Mieter selbst haftet für die durch Ihn oder seine Begleitperson/en schuldhaft zugefügten Schäden.
                  Schäden, die der Mieter verursacht, sind unaufgefordert vor Verlassen des Parkplatzes guenstig-parken-Baden-Aipark.de anzuzeigen!
                </li>
                <li>
                  Überbuchung<br />
                  guenstig-parken-Baden-Aipark.de sind berechtigt, auf Kosten und Gefahr des Besitzers das Fahrzeug aus wichtigem Grund abschleppen zu lassen, insbesondere wenn das
                  abgestellte Fahrzeug durch seine Beschaffenheit oder seinen Stand den übrigen Betrieb gefährdet oder wesentlich behindert oder zugelassen ist oder während der
                  Abstellzeit die Zulassung entzogen wird. Sollte das Fahrzeug nicht verschlossen sein und es dadurch offensichtlich diebstahlgefährdet sein behält der
                  Parkplatzbetreiber sich das Abschleppen lassen in Verbindung mit einer Sicherheitsverwahrung vor.
                </li>
                <li>
                  Schadensmeldung<br />
                  In seltenen Fällen kann es vorkommen, dass die Stellplätze des Parkplatzbetreibers bei der Ankunft des Kunden trotz seiner rechtzeitigen Anmeldung belegt
                  sind.guenstig-parken-Baden-Aipark.de kann in diesem Fall dem Kunden eine alternative PKW-Abstellfläche zum gleichen Preis anbieten. Sofern der Kunde hiervon
                  Gebrauch macht besteht kein Anspruch auf Minderung der Parkplatzgebühr oder nachträgliche Wandlung des Vertrages. Bei Nicht-Inanspruchnahme der
                  Stellplatzalternative wird dem Kunden das Recht zur fristlosen Kündigung der Buchung und ein Anspruch auf Erstattung des evt. gezahlten Buchungsbetrages
                  eingeräumt.
                </li>
                <li>
                  Der Schaden ist unmittelbar bei Erkennen und vor dem Verlassen des Parkplatzes dem Fahrdienst mitzuteilen. Nach Verlassen des Parkplatzes ohne vorherige Prüfung
                  eines Schadens durch den Fahrdienst gilt der PKW als einwandfrei zurückerhalten.
                </li>
                <li>Es kann nicht eine jederzeitige Schneeräumung der Parkplatzflächen gewährleistet werden.</li>
              </ol>
            </li>
            <li>
              <h5>Sondervereinbarungen</h5>
              Sondervereinbarungen oder Abweichungen dieser AGBs bedürfen der Schriftform.
            </li>
            <li>
              <h5>Unwirksamkeit</h5>
              Die Unwirksamkeit einzelner Bedingungen zieht nicht gleichzeitig die komplette Unwirksamkeit der gesamten AGBs nach sich.
            </li>
            <li>
              <h5>Gerichtsstand</h5>
              Gerichtsstand ist {{ config.court }}.<br />Telefon: {{ config.phone }}<br />E-Mail: {{ config.mail }}<br />Umsatzsteuer-Identifikationsnummer gemäß §27 a
              Umsatzsteuergesetz: {{ config.steuer }}
            </li>
            <li>
              <h5>Haftungshinweis</h5>
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren
              Betreiber verantwortlich.
            </li>
          </ol>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: { ...mapGetters(['config']) }
}
</script>

<style>
ol.main > li {
  counter-increment: root;
  margin: 3rem 0px;
}

ol.main > li > ol {
  counter-reset: subsection;
  list-style-type: none;
}

ol.main > li > ol > li {
  counter-increment: subsection;
  margin: 1rem 0.2rem;
}

ol.main > li > ol > li:before {
  content: counter(root) '.' counter(subsection) ' ';
}
</style>