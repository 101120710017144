<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h2 class="my-3">Datenschutzerklärung</h2>
          <div>
            <p class="n"></p>
            <p>Datenschutzerklärung</p>
            <p>
              Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung eines
              funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
            </p>
            <p><br /></p>
            <p>
              Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder
              ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten, wie das Erheben, das Erfassen,
              die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
              Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung.
            </p>
            <p><br /></p>
            <p>
              Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung personenbezogener
              Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir Sie nachfolgend über die
              von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener
              Verantwortung verarbeiten.
            </p>
            <p><br /></p>
            <p>Unsere Datenschutzerklärung ist wie folgt gegliedert:</p>
            <p><br /></p>
            <p>I. Informationen über uns als Verantwortliche</p>
            <p>II. Rechte der Nutzer und Betroffenen</p>
            <p>III. Informationen zur Datenverarbeitung</p>
            <p><br /></p>
            <p>I. Informationen über uns als Verantwortliche</p>
            <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
            <p><br /></p>
            <p>Restaurant Casa Daniele</p>
            <p>Hardtwaldstraße 1</p>
            <p>76549 Hügelsheim</p>
            <p>Deutschland</p>
            <p><br /></p>
            <p>Telefon: 01703439147</p>
            <p>E-Mail: parken-airpark@gmx.de</p>
            <p><br /></p>
            <p>Datenschutzbeauftragte/r beim Anbieter ist:</p>
            <p><br /></p>
            <p>Daniel Bechler</p>
            <p><br /></p>
            <p><br /></p>
            <p>II. Rechte der Nutzer und Betroffenen</p>
            <p>Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und Betroffenen das Recht</p>
            <p><br /></p>
            <p>
              auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie
              auf Kopien der Daten (vgl. auch Art. 15 DSGVO);
            </p>
            <p>auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch Art. 16 DSGVO);</p>
            <p>
              auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder, alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO
              erforderlich ist, auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
            </p>
            <p>
              auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
            </p>
            <p>
              auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie betreffenden Daten durch den Anbieter unter Verstoß gegen
              datenschutzrechtliche Bestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).
            </p>
            <p>
              Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den Anbieter offengelegt worden sind, über jedwede Berichtigung oder
              Löschung von Daten oder die Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu unterrichten. Diese Verpflichtung besteht
              jedoch nicht, soweit diese Mitteilung unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf Auskunft
              über diese Empfänger.
            </p>
            <p><br /></p>
            <p>
              Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten
              durch den Anbieter nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der
              Direktwerbung statthaft.
            </p>
            <p><br /></p>
            <p>III. Informationen zur Datenverarbeitung</p>
            <p>
              Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung der Daten keine
              gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren gemacht werden.
            </p>
            <p><br /></p>
            <p>Serverdaten</p>
            <p>
              Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an
              unseren Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres Internetbrowsers, das Betriebssystem, die Website, von der
              aus Sie auf unseren Internetauftritt gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum und Uhrzeit des jeweiligen
              Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die Nutzung unseres Internetauftritts erfolgt, erhoben.
            </p>
            <p><br /></p>
            <p>Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen Daten von Ihnen.</p>
            <p><br /></p>
            <p>
              Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität
              und Sicherheit unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur
              endgültigen Klärung eines Vorfalls ganz oder teilweise von der Löschung ausgenommen.
            </p>
            <p><br /></p>
            <p>Cookies</p>
            <p>a) Sitzungs-Cookies/Session-Cookies</p>
            <p>
              Wir verwenden mit unserem Internetauftritt sog. Cookies. Cookies sind kleine Textdateien oder andere Speichertechnologien, die durch den von Ihnen eingesetzten
              Internet-Browser auf Ihrem Endgerät ablegt und gespeichert werden. Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie
              beispielsweise Ihre Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. &nbsp;
            </p>
            <p><br /></p>
            <p>
              Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver und sicherer, da die Verarbeitung bspw. die Wiedergabe unseres Internetauftritts
              in unterschiedlichen Sprachen oder das Angebot einer Warenkorbfunktion ermöglicht.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung verarbeitet werden.</p>
            <p><br /></p>
            <p>
              Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der Funktionalität unseres
              Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
            </p>
            <p><br /></p>
            <p>Mit Schließen Ihres Internet-Browsers werden diese Session-Cookies gelöscht.</p>
            <p><br /></p>
            <p>b) Drittanbieter-Cookies</p>
            <p>
              Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der Funktionalitäten
              unseres Internetauftritts zusammenarbeiten, verwendet.
            </p>
            <p><br /></p>
            <p>
              Die Einzelheiten hierzu, insbesondere zu den Zwecken und den Rechtsgrundlagen der Verarbeitung solcher Drittanbieter-Cookies, entnehmen Sie bitte den nachfolgenden
              Informationen.
            </p>
            <p><br /></p>
            <p>c) Beseitigungsmöglichkeit</p>
            <p>
              Sie können die Installation der Cookies durch eine Einstellung Ihres Internet-Browsers verhindern oder einschränken. Ebenfalls können Sie bereits gespeicherte Cookies
              jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher
              bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support. Bei sog. Flash-Cookies kann die Verarbeitung
              allerdings nicht über die Einstellungen des Browsers unterbunden werden. Stattdessen müssen Sie insoweit die Einstellung Ihres Flash-Players ändern. Auch die hierfür
              erforderlichen Schritte und Maßnahmen hängen von Ihrem konkret genutzten Flash-Player ab. Bei Fragen benutzen Sie daher bitte ebenso die Hilfefunktion oder
              Dokumentation Ihres Flash-Players oder wenden sich an den Hersteller bzw. Benutzer-Support.
            </p>
            <p><br /></p>
            <p>
              Sollten Sie die Installation der Cookies verhindern oder einschränken, kann dies allerdings dazu führen, dass nicht sämtliche Funktionen unseres Internetauftritts
              vollumfänglich nutzbar sind.
            </p>
            <p><br /></p>
            <p>Vertragsabwicklung</p>
            <p>
              Die von Ihnen zur Inanspruchnahme unseres Waren- und/oder Dienstleistungsangebots übermittelten Daten werden von uns zum Zwecke der Vertragsabwicklung verarbeitet und
              sind insoweit erforderlich. Vertragsschluss und Vertragsabwicklung sind ohne Bereitstellung Ihrer Daten nicht möglich.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p><br /></p>
            <p>Wir löschen die Daten mit vollständiger Vertragsabwicklung, müssen dabei aber die steuer- und handelsrechtlichen Aufbewahrungsfristen beachten.</p>
            <p><br /></p>
            <p>
              Im Rahmen der Vertragsabwicklung geben wir Ihre Daten an das mit der Warenlieferung beauftragte Transportunternehmen oder an den Finanzdienstleister weiter, soweit
              die Weitergabe zur Warenauslieferung oder zu Bezahlzwecken erforderlich ist.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p><br /></p>
            <p>Kundenkonto / Registrierungsfunktion</p>
            <p>
              Falls Sie über unseren Internetauftritt ein Kundenkonto bei uns anlegen, werden wir die von Ihnen bei der Registrierung eingegebenen Daten (also bspw. Ihren Namen,
              Ihre Anschrift oder Ihre E-Mail-Adresse) ausschließlich für vorvertragliche Leistungen, für die Vertragserfüllung oder zum Zwecke der Kundenpflege (bspw. um Ihnen
              eine Übersicht über Ihre bisherigen Bestellungen bei uns zur Verfügung zu stellen oder um Ihnen die sog. Merkzettelfunktion anbieten zu können) erheben und speichern.
              Gleichzeitig speichern wir dann die IP-Adresse und das Datum Ihrer Registrierung nebst Uhrzeit. Eine Weitergabe dieser Daten an Dritte erfolgt natürlich nicht.
            </p>
            <p><br /></p>
            <p>
              Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in diese Verarbeitung eingeholt und auf diese Datenschutzerklärung verwiesen. Die dabei von uns
              erhobenen Daten werden ausschließlich für die Zurverfügungstellung des Kundenkontos verwendet.&nbsp;
            </p>
            <p><br /></p>
            <p>Soweit Sie in diese Verarbeitung einwilligen, ist Art. 6 Abs. 1 lit. a) DSGVO Rechtsgrundlage für die Verarbeitung.</p>
            <p><br /></p>
            <p>
              Sofern die Eröffnung des Kundenkontos zusätzlich auch vorvertraglichen Maßnahmen oder der Vertragserfüllung dient, so ist Rechtsgrundlage für diese Verarbeitung auch
              noch Art. 6 Abs. 1 lit. b) DSGVO.
            </p>
            <p><br /></p>
            <p>
              Die uns erteilte Einwilligung in die Eröffnung und den Unterhalt des Kundenkontos können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft
              widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.
            </p>
            <p><br /></p>
            <p>
              Die insoweit erhobenen Daten werden gelöscht, sobald die Verarbeitung nicht mehr erforderlich ist. Hierbei müssen wir aber steuer- und handelsrechtliche
              Aufbewahrungsfristen beachten.
            </p>
            <p><br /></p>
            <p>Prüfung von Bonität und Scoring</p>
            <p>
              Soweit wir Ihnen im Rahmen unseres Waren- oder Dienstleistungsangebots die grundsätzliche Möglichkeit einer Bezahlung per Rechnung eröffnen und Sie hiervon Gebrauch
              machen, behalten wir uns vor, bei einer Auskunftei (wie bspw. Creditreform, Schufa, Bürgel oder infoscore) eine Bonitätsauskunft auf der Basis
              mathematisch-statistischer Verfahren einzuholen. Hierzu werden Ihre Daten, soweit sie vertragserheblich sind, wie bspw. Ihren Namen und Ihre Anschrift, an die
              Auskunftei weiterleiten. Die anschließende Auskunft über die statistische Wahrscheinlichkeit eines Zahlungsausfalls verwenden wir für unsere Entscheidung, ob wir
              Ihnen die Bezahlung per Rechnung anbieten.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an der Ausfallsicherheit der Forderung gem. Art. 6 Abs.1 lit. f) DSGVO.</p>
            <p><br /></p>
            <p>Newsletter</p>
            <p>
              Falls Sie sich für unseren kostenlosen Newsletter anmelden, werden die von Ihnen hierzu abgefragten Daten, also Ihre E-Mail-Adresse sowie - optional - Ihr Name und
              Ihre Anschrift, an uns übermittelt. Gleichzeitig speichern wir die IP-Adresse des Internetanschlusses von dem aus Sie auf unseren Internetauftritt zugreifen sowie
              Datum und Uhrzeit Ihrer Anmeldung. Im Rahmen des weiteren Anmeldevorgangs werden wir Ihre Einwilligung in die Übersendung des Newsletters einholen, den Inhalt konkret
              beschreiben und auf diese Datenschutzerklärung verwiesen. Die dabei erhobenen Daten verwenden wir ausschließlich für den Newsletter-Versand – sie werden deshalb
              insbesondere auch nicht an Dritte weitergegeben.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO.</p>
            <p><br /></p>
            <p>
              Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über
              Ihren Widerruf in Kenntnis setzen oder den in jedem Newsletter enthaltenen Abmeldelink betätigen.
            </p>
            <p><br /></p>
            <p>Kontaktanfragen / Kontaktmöglichkeit</p>
            <p>
              Sofern Sie per Kontaktformular oder E-Mail mit uns in Kontakt treten, werden die dabei von Ihnen angegebenen Daten zur Bearbeitung Ihrer Anfrage genutzt. Die Angabe
              der Daten ist zur Bearbeitung und Beantwortung Ihre Anfrage erforderlich - ohne deren Bereitstellung können wir Ihre Anfrage nicht oder allenfalls eingeschränkt
              beantworten.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für diese Verarbeitung ist Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p><br /></p>
            <p>
              Ihre Daten werden gelöscht, sofern Ihre Anfrage abschließend beantwortet worden ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen, wie
              bspw. bei einer sich etwaig anschließenden Vertragsabwicklung.
            </p>
            <p><br /></p>
            <p>Nutzerbeiträge, Kommentare und Bewertungen</p>
            <p>
              Wir bieten Ihnen an, auf unseren Internetseiten Fragen, Antworten, Meinungen oder Bewertungen, nachfolgend nur „Beiträge genannt, zu veröffentlichen. Sofern Sie
              dieses Angebot in Anspruch nehmen, verarbeiten und veröffentlichen wir Ihren Beitrag, Datum und Uhrzeit der Einreichung sowie das von Ihnen ggf. genutzte Pseudonym.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO. Die Einwilligung können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen.
              Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.
            </p>
            <p><br /></p>
            <p>
              Darüber hinaus verarbeiten wir auch Ihre IP- und E-Mail-Adresse. Die IP-Adresse wird verarbeitet, weil wir ein berechtigtes Interesse daran haben, weitere Schritte
              einzuleiten oder zu unterstützen, sofern Ihr Beitrag in Rechte Dritter eingreift und/oder er sonst wie rechtswidrig erfolgt.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der ggf. notwendigen Rechtsverteidigung.</p>
            <p><br /></p>
            <p>Abonnement von Beiträgen</p>
            <p>
              Sofern Sie Beiträge auf unseren Internetseiten veröffentlichen, bieten wir Ihnen zusätzlich an, etwaige Folgebeiträge Dritter zu abonnieren. Um Sie über diese
              Folgebeiträge per E-Mail informieren zu können, verarbeiten wir Ihre E-Mail-Adresse.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage hierbei ist Art. 6 Abs. 1 lit. a) DSGVO. Die Einwilligung in dieses Abonnement können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die
              Zukunft widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen oder den in der jeweiligen E-Mail enthaltenen Abmeldelink betätigen.
            </p>
            <p><br /></p>
            <p>Google Analytics</p>
            <p>
              In unserem Internetauftritt setzen wir Google Analytics ein. Hierbei handelt es sich um einen Webanalysedienst der Google LLC, 1600 Amphitheatre Parkway, Mountain
              View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Der Dienst Google Analytics dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes
              Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Nutzungs- und nutzerbezogene Informationen, wie bspw. IP-Adresse, Ort, Zeit oder Häufigkeit des Besuchs unseres Internetauftritts, werden dabei an einen Server von
              Google in den USA übertragen und dort gespeichert. Allerdings nutzen wir Google Analytics mit der sog. Anonymisierungsfunktion. Durch diese Funktion kürzt Google die
              IP-Adresse schon innerhalb der EU bzw. des EWR.
            </p>
            <p><br /></p>
            <p>
              Die so erhobenen Daten werden wiederum von Google genutzt, um uns eine Auswertung über den Besuch unseres Internetauftritts sowie über die dortigen
              Nutzungsaktivitäten zur Verfügung zu stellen. Auch können diese Daten genutzt werden, um weitere Dienstleistungen zu erbringen, die mit der Nutzung unseres
              Internetauftritts und der Nutzung des Internets zusammenhängen.
            </p>
            <p><br /></p>
            <p>Google gibt an, Ihre IP-Adresse nicht mit anderen Daten zu verbinden. Zudem hält Google unter</p>
            <p><br /></p>
            <p>https://www.google.com/intl/de/policies/privacy/partners</p>
            <p><br /></p>
            <p>weitere datenschutzrechtliche Informationen für Sie bereit, so bspw. auch zu den Möglichkeiten, die Datennutzung zu unterbinden.</p>
            <p><br /></p>
            <p>Zudem bietet Google unter</p>
            <p><br /></p>
            <p>https://tools.google.com/dlpage/gaoptout?hl=de</p>
            <p><br /></p>
            <p>
              ein sog. Deaktivierungs-Add-on nebst weiteren Informationen hierzu an. Dieses Add-on lässt sich mit den gängigen Internet-Browsern installieren und bietet Ihnen
              weitergehende Kontrollmöglichkeit über die Daten, die Google bei Aufruf unseres Internetauftritts erfasst. Dabei teilt das Add-on dem JavaScript (ga.js) von Google
              Analytics mit, dass Informationen zum Besuch unseres Internetauftritts nicht an Google Analytics übermittelt werden sollen. Dies verhindert aber nicht, dass
              Informationen an uns oder an andere Webanalysedienste übermittelt werden. Ob und welche weiteren Webanalysedienste von uns eingesetzt werden, erfahren Sie natürlich
              ebenfalls in dieser Datenschutzerklärung.
            </p>
            <p><br /></p>
            <p>„Google+“-Social-Plug-in</p>
            <p>
              In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Google+ („Google Plus“) ein. Bei Google+ handelt es sich um einen Internetservice der Google
              LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
            <p><br /></p>
            <p>Weitergehende Informationen über die möglichen Plug-ins sowie über deren jeweilige Funktionen hält Google unter</p>
            <p><br /></p>
            <p>https://developers.google.com/+/web/&nbsp;</p>
            <p><br /></p>
            <p>für Sie bereit.</p>
            <p><br /></p>
            <p>
              Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den
              Servern von Google in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Google Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und
              Uhrzeit des Besuchs unserer Internetseiten erfasst.
            </p>
            <p><br /></p>
            <p>
              Sollten Sie bei Google eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten
              Informationen Ihres konkreten Besuchs von Google erkannt. Die so gesammelten Informationen weist Google womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern
              Sie also bspw. den sog. „Teilen“-Button von Google benutzen, werden diese Informationen in Ihrem Google-Nutzerkonto gespeichert und ggf. über die Plattform von Google
              veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Google ausloggen oder die entsprechenden
              Einstellungen in Ihrem Google-Benutzerkonto vornehmen.
            </p>
            <p><br /></p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Google in den unter</p>
            <p><br /></p>
            <p>https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>abrufbaren Datenschutzhinweisen bereit.</p>
            <p><br /></p>
            <p>Google-Maps</p>
            <p>
              In unserem Internetauftritt setzen wir Google Maps zur Darstellung unseres Standorts sowie zur Erstellung einer Anfahrtsbeschreibung ein. Es handelt sich hierbei um
              einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in
              den USA aufgebaut.
            </p>
            <p><br /></p>
            <p>
              Sofern Sie die in unseren Internetauftritt eingebundene Komponente Google Maps aufrufen, speichert Google über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät.
              Um unseren Standort anzuzeigen und eine Anfahrtsbeschreibung zu erstellen, werden Ihre Nutzereinstellungen und -daten verarbeitet. Hierbei können wir nicht
              ausschließen, dass Google Server in den USA einsetzt.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung der Funktionalität unseres Internetauftritts.</p>
            <p><br /></p>
            <p>
              Durch die so hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an welche IP-Adresse die
              Anfahrtsbeschreibung zu übermitteln ist.
            </p>
            <p><br /></p>
            <p>
              Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem
              Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.
            </p>
            <p><br /></p>
            <p>
              Zudem erfolgt die Nutzung von Google Maps sowie der über Google Maps erlangten Informationen nach den Google-Nutzungsbedingungen
              https://policies.google.com/terms?gl=DE&amp;hl=de und den Geschäftsbedingungen für Google Maps https://www.google.com/intl/de_de/help/terms_maps.html.
            </p>
            <p><br /></p>
            <p>Überdies bietet Google unter</p>
            <p><br /></p>
            <p>https://adssettings.google.com/authenticated</p>
            <p><br /></p>
            <p>https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>weitergehende Informationen an.</p>
            <p><br /></p>
            <p>Google reCAPTCHA</p>
            <p>
              In unserem Internetauftritt setzen wir Google reCAPTCHA zur Überprüfung und Vermeidung von Interaktionen auf unserer Internetseite durch automatisierte Zugriffe,
              bspw. durch sog. Bots, ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“
              genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Durch diesen Dienst kann Google ermitteln, von welcher Webseite eine Anfrage gesendet wird sowie von welcher IP-Adresse aus Sie die sog. reCAPTCHA-Eingabebox
              verwenden. Neben Ihrer IP-Adresse werden womöglich noch weitere Informationen durch Google erfasst, die für das Angebot und die Gewährleistung dieses Dienstes
              notwendig sind. &nbsp;&nbsp;
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Sicherheit unseres Internetauftritts sowie in der Abwehr unerwünschter,
              automatisierter Zugriffe in Form von Spam o.ä..
            </p>
            <p><br /></p>
            <p>Google bietet unter</p>
            <p><br /></p>
            <p>https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>weitergehende Informationen zu dem allgemeinen Umgang mit Ihren Nutzerdaten an.</p>
            <p><br /></p>
            <p>Google Fonts</p>
            <p>
              In unserem Internetauftritt setzen wir Google Fonts zur Darstellung externer Schriftarten ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Um die Darstellung bestimmter Schriften in unserem Internetauftritt zu ermöglichen, wird bei Aufruf unseres Internetauftritts eine Verbindung zu dem Google-Server in
              den USA aufgebaut.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Durch die bei Aufruf unseres Internetauftritts hergestellte Verbindung zu Google kann Google ermitteln, von welcher Website Ihre Anfrage gesendet worden ist und an
              welche IP-Adresse die Darstellung der Schrift zu übermitteln ist.
            </p>
            <p><br /></p>
            <p>Google bietet unter</p>
            <p><br /></p>
            <p>https://adssettings.google.com/authenticated</p>
            <p><br /></p>
            <p>https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
            <p><br /></p>
            <p>Matomo (vormals: PIWIK)</p>
            <p>
              In unserem Internetauftritt setzen wir Matomo (ehemals: „PIWIK“) ein. Hierbei handelt es sich um eine Open-Source-Software, mit der wir die Benutzung unseres
              Internetauftritts analysieren können. Hierbei werden Ihre IP-Adresse, die Website(s) unseres Internetauftritts, die Sie besuchen, die Website, von der aus Sie auf
              unseren Internetauftritt gewechselt haben (Referrer URL), Ihre Verweildauer auf unserem Internetauftritt sowie die Häufigkeit des Aufrufs einer unserer Websites
              verarbeitet.
            </p>
            <p><br /></p>
            <p>Zur Erfassung dieser Daten speichert Matomo über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät. Dieses Cookie ist eine Woche lang gültig.</p>
            <p><br /></p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse und Optimierung unseres Internetauftritts.</p>
            <p><br /></p>
            <p>
              Allerdings nutzen wir Matomo mit der Anonymisierungsfunktion „Automatically Anonymize Visitor IPs“. Diese Anonymisierungsfunktion kürzt Ihre IP-Adresse um zwei Bytes,
              sodass eine Zuordnung zu Ihnen bzw. zu dem von Ihnen genutzten Internetanschluss unmöglich ist.
            </p>
            <p><br /></p>
            <p>
              Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung des Cookies durch eine Einstellung in Ihrem Internet-Browsers
              zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.
            </p>
            <p><br /></p>
            <p>Darüber hinaus haben Sie die Möglichkeit, die Analyse Ihres Nutzungsverhaltens im Wege des sog. Opt-outs zu beenden. Mit dem Bestätigen des Links</p>
            <p><br /></p>
            <p><br /></p>
            <p><br /></p>
            <p>
              wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link
              erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.
            </p>
            <p><br /></p>
            <p>„Facebook“-Social-Plug-in</p>
            <p>
              In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Facebook ein. Bei Facebook handelt es sich um einen Internetservice der facebook Inc., 1601 S.
              California Ave, Palo Alto, CA 94304, USA. In der EU wird dieser Service wiederum von der Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland, betrieben,
              nachfolgend beide nur „Facebook“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Facebook, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
            <p><br /></p>
            <p>Weitergehende Informationen über die möglichen Plug-ins sowie über deren jeweilige Funktionen hält Facebook unter</p>
            <p><br /></p>
            <p>https://developers.facebook.com/docs/plugins/</p>
            <p><br /></p>
            <p>für Sie bereit.</p>
            <p><br /></p>
            <p>
              Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den
              Servern von Facebook in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Facebook Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum
              und Uhrzeit des Besuchs unserer Internetseiten erfasst.
            </p>
            <p><br /></p>
            <p>
              Sollten Sie bei Facebook eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten
              Informationen Ihres konkreten Besuchs von Facebook erkannt. Die so gesammelten Informationen weist Facebook womöglich Ihrem dortigen persönlichen Nutzerkonto zu.
              Sofern Sie also bspw. den sog. „Gefällt mir“-Button von Facebook benutzen, werden diese Informationen in Ihrem Facebook-Nutzerkonto gespeichert und ggf. über die
              Plattform von Facebook veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Facebook ausloggen oder
              durch den Einsatz eines Add-ons für Ihren Internetbrowser verhindern, dass das Laden des Facebook-Plug-in blockiert wird.
            </p>
            <p><br /></p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Facebook in den unter</p>
            <p><br /></p>
            <p>https://www.facebook.com/policy.php</p>
            <p><br /></p>
            <p>abrufbaren Datenschutzhinweisen bereit.</p>
            <p><br /></p>
            <p>„Twitter“-Social-Plug-in</p>
            <p>
              In unserem Internetauftritt setzen wir das Plug-in des Social-Networks Twitter ein. Bei Twitter handelt es sich um einen Internetservice der Twitter Inc., 795 Folsom
              St., Suite 600, San Francisco, CA 94107, USA, nachfolgend nur „Twitter“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Twitter, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts.</p>
            <p><br /></p>
            <p>
              Sofern das Plug-in auf einer der von Ihnen besuchten Seiten unseres Internetauftritts hinterlegt ist, lädt Ihr Internet-Browser eine Darstellung des Plug-ins von den
              Servern von Twitter in den USA herunter. Aus technischen Gründen ist es dabei notwendig, dass Twitter Ihre IP-Adresse verarbeitet. Daneben werden aber auch Datum und
              Uhrzeit des Besuchs unserer Internetseiten erfasst.
            </p>
            <p><br /></p>
            <p>
              Sollten Sie bei Twitter eingeloggt sein, während Sie eine unserer mit dem Plug-in versehenen Internetseite besuchen, werden die durch das Plug-in gesammelten
              Informationen Ihres konkreten Besuchs von Twitter erkannt. Die so gesammelten Informationen weist Twitter womöglich Ihrem dortigen persönlichen Nutzerkonto zu. Sofern
              Sie also bspw. den sog. „Teilen“-Button von Twitter benutzen, werden diese Informationen in Ihrem Twitter-Nutzerkonto gespeichert und ggf. über die Plattform von
              Twitter veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich entweder vor dem Besuch unseres Internetauftritts bei Twitter ausloggen oder die
              entsprechenden Einstellungen in Ihrem Twitter-Benutzerkonto vornehmen.
            </p>
            <p><br /></p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Twitter in den unter</p>
            <p><br /></p>
            <p>https://twitter.com/privacy</p>
            <p><br /></p>
            <p>abrufbaren Datenschutzhinweisen bereit.</p>
            <p><br /></p>
            <p>Zählpixel der Verwertungsgesellschaft WORT (VG WORT)</p>
            <p>
              In unserem Internetauftritt setzen wir das sog. Zählpixel der VG WORT des Anbieters INFOnline GmbH, Forum Bonn Nord, Brühler Str. 9, 53119 Bonn, ein und zwar in Form
              des sog. SZM (Skalierbares Zentrales Messverfahren).
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts
              sowie in der Berücksichtigung urheberrechtlicher Interessen bezüglich der Inhalte unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Bei dem Zählpixel handelt es sich um eine Grafik, die in unserem Internetauftritt platziert ist und die statistische Kennzahlen ermittelt. Hierdurch wird es möglich,
              die Kopierwahrscheinlichkeit von Texten anhand der Anzahl der Aufrufe und der konkreten Inhalte unseres Internetauftritts zu berechnen. Die Erhebung dieser Daten
              erfolgt anonym. Um die Zugriffszahlen sowie Ihren ggf. wiederkehrenden Besuch erfassen zu können, wird entweder ein sog. Session-Cookie durch Ihren Internet-Browser
              auf Ihrem Endgerät abgelegt oder eine Signatur, die aus verschiedenen Informationen Ihres Internet-Browsers (bspw. Useragent, Bildschirmauflösung, o.ä.) erstellt
              wird, eingesetzt. Ihre IP-Adresse wird jedoch nur in anonymisierter Form verarbeitet. Als einzelner Nutzer sind Sie dabei zu keinem Zeitpunkt identifizierbar.
            </p>
            <p><br /></p>
            <p>
              Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung des Cookies durch eine Einstellung in Ihrem Internet-Browsers
              zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“. Darüber hinaus haben Sie die Möglichkeit, die SZM-Messung im Wege des sog. Opt-outs
              zu beenden. Mit dem Bestätigen des Links
            </p>
            <p><br /></p>
            <p>https://optout.ioam.de/</p>
            <p><br /></p>
            <p>
              wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link
              erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.
            </p>
            <p><br /></p>
            <p>YouTube</p>
            <p>
              In unserem Internetauftritt setzen wir YouTube ein. Hierbei handelt es sich um ein Videoportal der YouTube LLC., 901 Cherry Ave., 94066 San Bruno, CA, USA,
              nachfolgend nur „YouTube“ genannt.
            </p>
            <p><br /></p>
            <p>YouTube ist ein Tochterunternehmen der Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.</p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>
              garantiert Google und damit auch das Tochterunternehmen YouTube, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten
              werden.
            </p>
            <p><br /></p>
            <p>
              Wir nutzen YouTube im Zusammenhang mit der Funktion „Erweiterter Datenschutzmodus“, um Ihnen Videos anzeigen zu können. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f)
              DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts. Die Funktion „Erweiterter Datenschutzmodus“ bewirkt laut Angaben von
              YouTube, dass die nachfolgend noch näher bezeichneten Daten nur dann an den Server von YouTube übermittelt werden, wenn Sie ein Video auch tatsächlich starten.
            </p>
            <p><br /></p>
            <p>
              Ohne diesen „Erweiterten Datenschutz“ wird eine Verbindung zum Server von YouTube in den USA hergestellt, sobald Sie eine unserer Internetseiten, auf der ein
              YouTube-Video eingebettet ist, aufrufen.
            </p>
            <p><br /></p>
            <p>
              Diese Verbindung ist erforderlich, um das jeweilige Video auf unserer Internetseite über Ihren Internet-Browser darstellen zu können. Im Zuge dessen wird YouTube
              zumindest Ihre IP-Adresse, das Datum nebst Uhrzeit sowie die von Ihnen besuchte Internetseite erfassen und verarbeiten. Zudem wird eine Verbindung zu dem
              Werbenetzwerk „DoubleClick“ von Google hergestellt.
            </p>
            <p><br /></p>
            <p>
              Sollten Sie gleichzeitig bei YouTube eingeloggt sein, weist YouTube die Verbindungsinformationen Ihrem YouTube-Konto zu. Wenn Sie das verhindern möchten, müssen Sie
              sich entweder vor dem Besuch unseres Internetauftritts bei YouTube ausloggen oder die entsprechenden Einstellungen in Ihrem YouTube-Benutzerkonto vornehmen.
            </p>
            <p><br /></p>
            <p>
              Zum Zwecke der Funktionalität sowie zur Analyse des Nutzungsverhaltens speichert YouTube dauerhaft Cookies über Ihren Internet-Browser auf Ihrem Endgerät. Falls Sie
              mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung der Cookies durch eine Einstellung in Ihrem Internet-Browsers zu
              verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.
            </p>
            <p><br /></p>
            <p>Weitergehende Informationen über die Erhebung und Nutzung von Daten sowie Ihre diesbezüglichen Rechte und Schutzmöglichkeiten hält Google in den unter</p>
            <p><br /></p>
            <p>https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>abrufbaren Datenschutzhinweisen bereit.</p>
            <p><br /></p>
            <p>Webanalyse etracker</p>
            <p>
              In unserem Internetauftritt setzen wir etracker ein. Hierbei handelt es sich um einen Webanalysedienst der etracker GmbH, Erste Brunnenstr. 1, 20459 Hamburg,
              nachfolgend nur „etracker“ genannt.
            </p>
            <p><br /></p>
            <p>
              etracker dient uns zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt
              in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Zur Analyse des Nutzungsverhaltens speichert etracker Cookies über Ihren Internet-Browser auf Ihrem Endgerät und erstellt dabei ein pseudonymes Nutzungsprofil. Die
              dabei verarbeiteten Daten werden allerdings nicht ohne Ihre gesonderte Zustimmung zu Ihrer persönlichen Identifikation verwendet. Genauso wenig werden diese Daten mit
              anderen personenbezogenen Daten zusammengeführt.
            </p>
            <p><br /></p>
            <p>
              Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung des Cookies durch eine Einstellung in Ihrem Internet-Browsers
              zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.
            </p>
            <p><br /></p>
            <p>Darüber hinaus haben Sie die Möglichkeit, die Analyse Ihres Nutzungsverhaltens im Wege des sog. Opt-outs zu beenden. Mit dem Bestätigen des Links</p>
            <p><br /></p>
            <p>http://www.etracker.de/privacy?et=V23Jbb</p>
            <p><br /></p>
            <p>
              wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link
              erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.
            </p>
            <p><br /></p>
            <p>Webanalyse Hotjar</p>
            <p>
              In unserem Internetauftritt setzen wir Hotjar ein. Hierbei handelt es sich um einen Webanalysedienst der Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia
              Zammit Street, St Julians STJ 1000, Malta, Europe, nachfolgend nur „Hotjar“ genannt.
            </p>
            <p><br /></p>
            <p>
              Hotjar dient uns zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in
              der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Durch Hotjar können wir Ihre Nutzungsverhalten auf unserem Internetauftritt, wie bspw. Ihre Mausbewegungen oder Mausklicks, protokollieren und auswerten. Ihre Besuch
              unseres Internetauftritts wird dabei aber anonymisiert. Zusätzlich werden durch Hotjar Informationen über Ihr Betriebssystem, Ihren Internet-Browser, eingehende oder
              ausgehende Verweise („Links“), die geographische Herkunft sowie Art und Auslösung des von Ihnen eingesetzten Endgeräts ausgewertet und zu statistischen Zwecken
              aufbereitet. Ebenfalls kann Hotjar ein direktes Feedback von Ihnen einholen. Zudem bietet Hotjar unter
            </p>
            <p><br /></p>
            <p>https://www.hotjar.com/privacy</p>
            <p><br /></p>
            <p>weitergehende Datenschutzinformationen an.</p>
            <p><br /></p>
            <p>Darüber hinaus haben Sie die Möglichkeit, die Analyse Ihres Nutzungsverhaltens im Wege des sog. Opt-outs zu beenden. Mit dem Bestätigen des Links</p>
            <p><br /></p>
            <p>https://www.hotjar.com/opt-out</p>
            <p><br /></p>
            <p>
              wird über Ihren Internet-Browser ein Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie den obigen Link
              erneut betätigen müssen, sofern Sie die auf Ihrem Endgerät gespeicherten Cookies löschen.
            </p>
            <p><br /></p>
            <p>Webanalyse Criteo</p>
            <p>
              In unserem Internetauftritt setzen wir zur Analyse sowie zur individuellen Einblendung von Werbung Criteo ein. Bei Criteo handelt es sich um einen Dienst der Criteo
              SA, 32 Rue Blanche, 75009 Paris, nachfolgend nur „Criteo“ genannt.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres
              Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Über sog. Tracking-Cookies und ähnliche Speichertechniken werden Informationen über Ihren Besuch unseres Internetauftritts und Ihre Aktivitäten auf den Internetseiten
              unserer Werbepartner erfasst. Bei diesen Informationen handelt es sich bspw. um die Anzahl der von Ihnen betrachteten Unterseiten, Werbeanzeigen und Produkte, die von
              Ihnen genutzte Suchfunktion, Informationen über Ihr Endgerät (Gerätetyp, Betriebssystem, Version) oder über Ihren Standort, um bspw. nur regional verfügbare Produkte
              oder Leistungen zu bewerben. Hierzu setzt Criteo eine technische ID ein, die die von Ihnen betrachteten Produkte und Ihr Suchverhalten erfasst. Nach den Angaben von
              Criteo ist hierdurch aber eine Identifizierung Ihrer Person nicht – es werden lediglich anonyme Informationen über Ihre möglichen Interessen ermittelt.
            </p>
            <p><br /></p>
            <p>
              Nach Auswertung der gesammelten Informationen platziert Criteo individuelle Werbeanzeigen in unserem Internetauftritt oder auf den Internetseiten unserer Werbepartner
              (sog. Publisher).
            </p>
            <p><br /></p>
            <p>
              Die Werbepartner können anhand von Cookies, die über Ihren Internet-Browser auf Ihrem Endgerät gespeichert werden, erkennen, ob bei Ihrem Aufruf einer Internetseite
              eine Werbeanzeige aus dem
            </p>
            <p><br /></p>
            <p>Criteo-Werbenetzwerk angezeigt werden soll.</p>
            <p><br /></p>
            <p>Dabei verarbeitet Criteo Ihre vollständige IP-Adresse ausschließlich zu den folgenden Zwecken:</p>
            <p><br /></p>
            <p>- Aufdeckung von Betrugsstraftaten, bspw. zur Feststellung von Besuchen, die nicht auf ein menschliches Verhalten zurückzuführen sind,</p>
            <p><br /></p>
            <p>- Attribution von Verkäufen;</p>
            <p><br /></p>
            <p>- Marketing-Reports mit aggregierten Daten. &nbsp;</p>
            <p><br /></p>
            <p>Criteo bietet unter</p>
            <p><br /></p>
            <p>https://www.criteo.com/de/privacy/</p>
            <p><br /></p>
            <p>weitergehende Datenschutzinformationen sowie Informationen über die Analyse und Werbeeinblendungen an.</p>
            <p><br /></p>
            <p>
              Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung der Cookies durch eine Einstellung in Ihrem Internet-Browsers
              zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.
            </p>
            <p><br /></p>
            <p>
              Unter dem vorstehenden Link können Sie auch die Analyse Ihres Besuchs im Wege eines sog. Opt-out-Cookies unterbinden. Dabei wird über Ihren Internet-Browser ein
              Cookie auf Ihrem Endgerät gespeichert, das die weitere Analyse verhindert. Bitte beachten Sie aber, dass Sie dieses Cookie erneut setzen müssen, sofern Sie die auf
              Ihrem Endgerät gespeicherten Cookies löschen.
            </p>
            <p><br /></p>
            <p>MailChimp - Newsletter</p>
            <p>Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren Internetauftritt für unsere kostenlosen Newsletter anmelden zu können.</p>
            <p><br /></p>
            <p>
              Zum Newsletterversand setzen wir MailChimp, einen Dienst der The Rocket Science Group, LLC, 512 Means Street, Suite 404, Atlanta, GA 30318, USA, nachfolgend nur „The
              Rocket Science Group“ genannt, ein.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active</p>
            <p><br /></p>
            <p>
              garantiert The Rocket Science Group, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden. Zudem bietet The Rocket
              Science Group unter
            </p>
            <p><br /></p>
            <p>http://mailchimp.com/legal/privacy/</p>
            <p><br /></p>
            <p>weitergehende Datenschutzinformationen an.</p>
            <p><br /></p>
            <p>
              Falls Sie sich zu unserem Newsletter-Versand anmelden, werden die während des Anmeldevorgangs abgefragten Daten, wie Ihre E-Mail-Adresse sowie, optional, Ihr Name
              nebst Anschrift, durch The Rocket Science Group verarbeitet. Zudem werden Ihre IP-Adresse sowie das Datum Ihrer Anmeldung nebst Uhrzeit gespeichert. Im Rahmen des
              weiteren Anmeldevorgangs wird Ihre Einwilligung in die Übersendung des Newsletters eingeholt, der Inhalt konkret beschreiben und auf diese Datenschutzerklärung
              verwiesen.
            </p>
            <p><br /></p>
            <p>
              Der anschließend über The Rocket Science Group versandte Newsletter enthält zudem einen sog. Zählpixel, auch Web Beacon genannt“. Mit Hilfe dieses Zählpixels können
              wir auswerten, ob und wann Sie unseren Newsletter gelesen haben und ob Sie den in dem Newsletter etwaig enthaltenen weiterführenden Links gefolgt sind. Neben weiteren
              technischen Daten, wie bspw. die Daten Ihres EDV-Systems und Ihre IP-Adresse, werden die dabei verarbeiteten Daten gespeichert, damit wir unser Newsletter-Angebot
              optimieren und auf die Wünsche der Leser eingehen können. Die Daten werden also zur Steigerung der Qualität und Attraktivität unseres Newsletter-Angebots zu steigern.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für den Versand des Newsletters und die Analyse ist Art. 6 Abs. 1 lit. a.) DSGVO.</p>
            <p><br /></p>
            <p>
              Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über
              Ihren Widerruf in Kenntnis setzen oder den in jedem Newsletter enthaltenen Abmeldelink betätigen.
            </p>
            <p><br /></p>
            <p>Jetpack - WordPress Stats</p>
            <p>
              In unserem Internetauftritt setzen wir Jetpack mit der Erweiterung „WordPress Stats“ ein. Hierbei handelt es sich um einen Webanalysedienst der Automattic Inc., 132
              Hawthorne Street, San Francisco, CA 94107, USA, nachfolgend nur „Automattic“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000CbqcAAC&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Automattic, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Der Dienst Jetpack - WordPress Stats dient zur Analyse des Nutzungsverhaltens unseres Internetauftritts. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser
              berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>Zur Analyse des Nutzungsverhaltens speichert Jetpack - WordPress Stats Cookies über Ihren Internet-Browser auf Ihrem Endgerät.</p>
            <p><br /></p>
            <p>
              Bei der Verarbeitung werden Ihre IP-Adresse, die Website(s) unseres Internetauftritts, die Sie besuchen, die Website, von der aus Sie auf unseren Internetauftritt
              gewechselt haben (Referrer URL), Ihre Verweildauer auf unserem Internetauftritt sowie die Häufigkeit des Aufrufs einer unserer Websites erfasst. Die dabei erhobenen
              Daten werden auf einem Server von Automattic in den USA gespeichert. Ihre IP-Adresse wird allerdings unverzüglich nach der Verarbeitung und noch vor ihrer Speicherung
              anonymisiert.
            </p>
            <p><br /></p>
            <p>
              Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung des Cookies durch eine Einstellung in Ihrem Internet-Browsers
              zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.
            </p>
            <p><br /></p>
            <p>„Shariff“-Social-Media-Buttons</p>
            <p>
              In unserem Internetauftritt setzten wir die Plug-ins der nachfolgend genannten Social-Networks ein. Zur Einbindung dieser Plug-ins nutzen wir wiederum das Plug-in
              „Shariff“.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Qualitätsverbesserung unseres Internetauftritts</p>
            <p><br /></p>
            <p>
              Shariff ist ein Open-Source-Programm, das von c’t und heise entwickelt worden ist. Durch die Einbindung dieses Plug-ins wird im Wege verlinkter Grafiken verhindert,
              dass die nachfolgend näher benannten Social-Network-Plug-ins bei Ihrem Besuch unserer Internetseite(n), auf der/denen wiederum das jeweilige Social-Network-Plug-in
              eingebunden ist, automatisch eine Verbindung zu dem jeweiligen Server des Social-Network-Plug-ins herstellt. Erst wenn Sie auf eine dieser verlinkten Grafiken
              klicken, werden Sie zu dem Dienst des jeweiligen Social-Networks weitergeleitet. Auch erst dann werden durch das jeweilige Social-Network Informationen über den
              Nutzungsvorgang erfasst. Zu diesen Informationen zählen bspw. Ihre IP-Adresse, das Datum nebst Uhrzeit sowie die von Ihnen besuchte Seite unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Sollten Sie bei einem der Social-Network-Dienste eingeloggt sein, während Sie eine unserer mit dem damit korrespondieren Plug-in versehenen Internetseite besuchen,
              kann der Anbieter des jeweiligen Social-Networks womöglich die so gesammelten Informationen Ihres konkreten Besuchs erkennen und Ihrem persönlichen Nutzerkonto
              zuordnen bzw. hierüber veröffentlichen. Sofern Sie also bspw. den sog. „Teilen“-Button des jeweiligen Social-Networks benutzen, werden diese Informationen ggf. in
              Ihrem dortigen Nutzerkonto gespeichert und über die Plattform des jeweiligen Social-Network-Anbieters veröffentlicht. Wenn Sie das verhindern möchten, müssen Sie sich
              entweder vor dem Klick auf die Grafik bei dem jeweiligen Social-Network ausloggen oder die entsprechenden Einstellungen in Ihrem Benutzerkonto des Social-Networks
              vornehmen.
            </p>
            <p><br /></p>
            <p>Weitergehende Informationen über „Shariff“ hält heise unter</p>
            <p><br /></p>
            <p>http://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html</p>
            <p><br /></p>
            <p>bereit.</p>
            <p><br /></p>
            <p>Folgende Social-Networks sind in unseren Internetauftritt eingebunden:</p>
            <p><br /></p>
            <p>Google+ der Google LLC, 1600 Amphitheatre Parkway, Mountain View, Kalifornien, 94043, USA.</p>
            <p><br /></p>
            <p>Datenschutzinformationen sind zu finden unter https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Facebook der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA, betrieben innerhalb der EU durch Facebook Ireland Limited, 4 Grand Canal Square, Dublin
              2, Irland.&nbsp;
            </p>
            <p><br /></p>
            <p>Datenschutzinformationen sind zu finden unter https://www.facebook.com/policy.php</p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Facebook, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>Twitter der Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA.</p>
            <p><br /></p>
            <p>Datenschutzinformationen sind zu finden unter https://twitter.com/privacy</p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Twitter, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>Google AdWords mit Conversion-Tracking</p>
            <p>
              In unserem Internetauftritt setzen wir die Werbe-Komponente Google AdWords und dabei das sog. Conversion-Tracking ein. Es handelt sich hierbei um einen Dienst der
              Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Wir nutzen das Conversion-Tracking zur zielgerichteten Bewerbung unseres Angebots. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt
              in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Falls Sie auf eine von Google geschaltete Anzeige klicken, speichert das von uns eingesetzte Conversion-Tracking ein Cookie auf Ihrem Endgerät. Diese sog.
              Conversion-Cookies verlieren mit Ablauf von 30 Tagen ihre Gültigkeit und dienen im Übrigen nicht Ihrer persönlichen Identifikation.
            </p>
            <p><br /></p>
            <p>
              Sofern das Cookie noch gültig ist und Sie eine bestimmte Seite unseres Internetauftritts besuchen, können sowohl wir als auch Google auswerten, dass Sie auf eine
              unserer bei Google platzierten Anzeigen geklickt haben und dass Sie anschließend auf unseren Internetauftritt weitergeleitet worden sind.
            </p>
            <p><br /></p>
            <p>
              Durch die so eingeholten Informationen erstellt Google uns eine Statistik über den Besuch unseres Internetauftritts. Zudem erhalten wir hierdurch Informationen über
              die Anzahl der Nutzer, die auf unsere Anzeige(n) geklickt haben sowie über die anschließend aufgerufenen Seiten unseres Internetauftritts. Weder wir noch Dritte, die
              ebenfalls Google-AdWords einsetzten, werden hierdurch allerdings in die Lage versetzt, Sie auf diesem Wege zu identifizieren.
            </p>
            <p><br /></p>
            <p>
              Durch die entsprechenden Einstellungen Ihres Internet-Browsers können Sie zudem die Installation der Cookies verhindern oder einschränken. Gleichzeitig können Sie
              bereits gespeicherte Cookies jederzeit löschen. Die hierfür erforderlichen Schritte und Maßnahmen hängen jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei
              Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.
            </p>
            <p><br /></p>
            <p>Ferner bietet auch Google unter</p>
            <p><br /></p>
            <p>https://services.google.com/sitestats/de.html</p>
            <p><br /></p>
            <p>http://www.google.com/policies/technologies/ads/&nbsp;</p>
            <p><br /></p>
            <p>http://www.google.de/policies/privacy/</p>
            <p><br /></p>
            <p>weitergehende Informationen zu diesem Thema und dabei insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung an.</p>
            <p><br /></p>
            <p>Google AdSense</p>
            <p>
              In unserem Internetauftritt setzen wir zur Einbindung von Werbeanzeigen Google AdSense ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600 Amphitheatre
              Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Durch Google AdSense werden Cookies sowie sog. Web Beacons über Ihren Internet-Browser auf Ihrem Endgerät gespeichert. Hierdurch ermöglicht uns Google die Analyse der
              Nutzung unseres Internetauftritts durch Sie. Die so erfassten Informationen werden neben Ihrer IP-Adresse und der Ihnen angezeigten Werbeformate an Google in die USA
              übertragen und dort gespeichert. Ferner kann Google diese Informationen an Vertragspartner weitergeben. Google erklärt allerdings, dass Ihre IP-Adresse nicht mit
              anderen Daten von Ihnen zusammengeführt würden.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres
              Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Sofern Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Installation der Cookies durch die entsprechenden Einstellungen in Ihrem
              Internet-Browser zu verhindern. Einzelheiten hierzu finden Sie vorstehend unter dem Punkt „Cookies“.
            </p>
            <p><br /></p>
            <p>Zudem bietet Google unter</p>
            <p><br /></p>
            <p>https://policies.google.com/privacy</p>
            <p><br /></p>
            <p>https://adssettings.google.com/authenticated</p>
            <p><br /></p>
            <p>weitere Informationen an und zwar insbesondere zu den Möglichkeiten der Unterbindung der Datennutzung.</p>
            <p><br /></p>
            <p>Google Remarketing oder "Ähnliche Zielgruppen"-Komponente von Google</p>
            <p>
              In unserem Internetauftritt setzen wir die Remarketing- oder „Ähnliche Zielgruppen“-Funktion ein. Es handelt sich hierbei um einen Dienst der Google LLC, 1600
              Amphitheatre Parkway, Mountain View, CA 94043 USA, nachfolgend nur „Google“ genannt.
            </p>
            <p><br /></p>
            <p>Durch die Zertifizierung nach dem EU-US-Datenschutzschild („EU-US Privacy Shield“)</p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert Google, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden.</p>
            <p><br /></p>
            <p>
              Wir nutzen diese Funktion, um interessenbezogene, personalisierte Werbung auf Internetseiten Dritter, die ebenfalls an dem Werbe-Netzwerk von Google teilnehmen, zu
              schalten.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO. Unser berechtigtes Interesse liegt in der Analyse, Optimierung und dem wirtschaftlichen Betrieb unseres
              Internetauftritts.
            </p>
            <p><br /></p>
            <p>
              Damit dieser Werbe-Dienst ermöglicht werden kann, speichert Google während Ihres Besuchs unseres Internetauftritts über Ihren Internet-Browser ein Cookie mit einer
              Zahlenfolge auf Ihrem Endgerät. Dieses Cookie erfasst in anonymisierter Form sowohl Ihren Besuch als auch die Nutzung unseres Internetauftritts. Personenbezogene
              Daten wird dabei allerdings nicht weitergegeben. Sofern Sie anschließend den Internetauftritt eines Dritten besuchen, der seinerseits ebenfalls das Werbe-Netzwerk von
              Google nutzt, werden womöglich Werbeeinblendungen erscheinen, die einen Bezug zu unserem Internetauftritt bzw. zu unseren dortigen Angeboten aufweisen.
            </p>
            <p><br /></p>
            <p>Zur dauerhaften Deaktivierung dieser Funktion bietet Google für die gängigsten Internet-Browser über</p>
            <p><br /></p>
            <p>https://www.google.com/settings/ads/plugin</p>
            <p><br /></p>
            <p>ein Browser-Plugin an.</p>
            <p><br /></p>
            <p>Ebenfalls kann die Verwendung von Cookies bestimmter Anbieter bspw. über</p>
            <p><br /></p>
            <p>http://www.youronlinechoices.com/uk/your-ad-choices</p>
            <p><br /></p>
            <p>oder</p>
            <p><br /></p>
            <p>http://www.networkadvertising.org/choices/</p>
            <p><br /></p>
            <p>per Opt-out deaktiviert werden.</p>
            <p><br /></p>
            <p>
              Durch das sog. Cross-Device-Marketing kann Google Ihr Nutzungsverhalten unter Umständen auch über mehrere Endgeräte hinweg verfolgen, sodass Ihnen womöglich selbst
              dann interessenbezogene, personalisierte Werbung angezeigt wird, wenn Sie das Endgerät wechseln. Dies setzt allerdings voraus, dass Sie der Verknüpfung Ihrer
              Browserverläufe mit Ihrem bestehenden Google-Konto zugestimmt haben.
            </p>
            <p><br /></p>
            <p>Google bietet weitergehende Informationen zu Google Remarketing unter</p>
            <p><br /></p>
            <p>http://www.google.com/privacy/ads/</p>
            <p><br /></p>
            <p>an.</p>
            <p><br /></p>
            <p>affilinet-Tracking-Cookies</p>
            <p>
              In unserem Internetauftritt bewerben wir auch Angebote und Leistungen Dritter. Sofern Sie aufgrund unserer Werbung für diese Drittangebote einen Vertrag mit dem
              Drittanbieter schließen, erhalten wir hierfür eine Provision.
            </p>
            <p><br /></p>
            <p>
              Um die erfolgreiche Vermittlung korrekt erfassen zu können, setzen wir das sog. affilinet-Tracking-Cookie ein. Dieses Cookie speichert aber keine Ihrer
              personenbezogenen Daten. Es wird lediglich unsere Identifikationsnummer als vermittelnder Anbieter sowie die Ordnungsnummer des von Ihnen angeklickten Werbemittels
              (bspw. eines Banners oder eines Textlinks) erfasst. Diese Informationenbenötigen wir zum Zwecke der Zahlungsabwicklung bzw. Auszahlung unserer Provisionen.
            </p>
            <p><br /></p>
            <p>
              Falls Sie mit dieser Verarbeitung nicht einverstanden sind, haben Sie die Möglichkeit, die Speicherung der Cookies durch eine Einstellung in Ihrem Internet-Browsers
              zu verhindern. Nähere Informationen hierzu finden Sie vorstehend unter „Cookies“.
            </p>
            <p><br /></p>
            <p>Newsletter per WhatsApp</p>
            <p>
              Unseren kostenlosen Newsletter können Sie auch über den Instant-Messaging-Dienst WhatsApp beziehen. WhatsApp ist ein Dienst der WhatsApp Ireland Limited, 4 Grand
              Canal Square, Grand Canal Harbour, Dublin 2, Irland, einem Tochterunternehmen der WhatsApp Inc., 1601 Willow Road, Menlo Park, California 94025, USA, beide
              nachfolgend nur „WhatsApp“ genannt. Teilweise erfolgt die Verarbeitung der Nutzerdaten auf Servern von WhatsApp in den USA.Durch die Zertifizierung nach dem
              EU-US-Datenschutzschild („EU-US Privacy Shield“)
            </p>
            <p><br /></p>
            <p>https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&amp;status=Active</p>
            <p><br /></p>
            <p>garantiert WhatsApp aber, dass die Datenschutzvorgaben der EU auch bei der Verarbeitung von Daten in den USA eingehalten werden. Zudem bietet WhatsApp unter</p>
            <p><br /></p>
            <p>https://www.whatsapp.com/legal/#privacy-policy</p>
            <p><br /></p>
            <p>weitergehende Datenschutzinformationen an</p>
            <p><br /></p>
            <p>
              Um unseren Newsletter per WhatsApp empfangen zu können, benötigen Sie ein WhatsApp Nutzerkonto. Einzelheiten darüber, welche Daten WhatsApp bei der Registrierung
              erhebt, können Sie den vorgenannten Datenschutzinformationen von WhatsApp entnehmen.
            </p>
            <p><br /></p>
            <p>
              Falls Sie sich dann zu unserem Newsletter-Versand per WhatsApp anmelden, wird die von Ihnen während des Anmeldevorgangs eingegebene Mobilfunkrufnummer durch WhatsApp
              verarbeitet. Zudem werden Ihre IP-Adresse sowie das Datum Ihrer Anmeldung nebst Uhrzeit gespeichert. Im Rahmen des weiteren Anmeldevorgangs wird Ihre Einwilligung in
              die Übersendung des Newsletters eingeholt, der Inhalt konkret beschreiben und auf diese Datenschutzerklärung verwiesen.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für den Versand des Newsletters und die Analyse ist Art. 6 Abs. 1 lit. a.) DSGVO.</p>
            <p><br /></p>
            <p>
              Die Einwilligung in den Newsletter-Versand können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft widerrufen. Hierzu müssen Sie uns lediglich über
              Ihren Widerruf in Kenntnis setzen. Ebenfalls können Sie den Newsletterempfang durch eine Einstellung in der WhatsApp-Software auf Ihrem Endgerät blockieren.
            </p>
            <p><br /></p>
            <p>Gewinnspiel</p>
            <p>
              Über unseren Internetauftritt bieten wir Ihnen die Teilnahme an Gewinnspielen an. Sofern Sie an einem unserer Gewinnspiele teilnehmen, werden die von Ihnen anlässlich
              der Teilnahme eingegebenen Daten ohne Ihre weitergehende Einwilligung, aber natürlich ausschließlich nur zur Durchführung und Abwicklung des jeweiligen Gewinnspiels,
              verarbeitet.
            </p>
            <p><br /></p>
            <p>
              Im Rahmen der Abwicklung des Gewinnspiels geben wir Ihre Daten an das mit der Warenlieferung beauftragte Transportunternehmen oder an einen Finanzdienstleister
              weiter, soweit die Weitergabe zur Lieferung bzw. Auszahlung Ihres Gewinns erforderlich ist. Sofern Ihre Daten im Falle eines Gewinns veröffentlicht werden, werden Sie
              hierauf im Rahmen der Einwilligungserklärung hingewiesen.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für die Weitergabe der Daten ist dann Art. 6 Abs. 1 lit. b) DSGVO.</p>
            <p><br /></p>
            <p>
              Ihre Einwilligung in die Verarbeitung der Daten zur Teilnahme an unseren Gewinnspielen können Sie gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung für die Zukunft
              widerrufen. Hierzu müssen Sie uns lediglich über Ihren Widerruf in Kenntnis setzen.
            </p>
            <p><br /></p>
            <p>Online-Stellenbewerbungen / Veröffentlichung von Stellenanzeigen</p>
            <p>
              Wir bieten Ihnen die Möglichkeit an, sich bei uns über unseren Internetauftritt bewerben zu können. Bei diesen digitalen Bewerbungen werden Ihre Bewerber- und
              Bewerbungsdaten von uns zur Abwicklung des Bewerbungsverfahrens elektronisch erhoben und verarbeitet.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für diese Verarbeitung ist § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</p>
            <p><br /></p>
            <p>
              Sofern nach dem Bewerbungsverfahren ein Arbeitsvertrag geschlossen wird, speichern wir Ihre bei der Bewerbung übermittelten Daten in Ihrer Personalakte zum Zwecke des
              üblichen Organisations- und Verwaltungsprozesses – dies natürlich unter Beachtung der weitergehenden rechtlichen Verpflichtungen.
            </p>
            <p><br /></p>
            <p>Rechtsgrundlage für diese Verarbeitung ist ebenfalls § 26 Abs. 1 S. 1 BDSG i.V.m. Art. 88 Abs. 1 DSGVO.</p>
            <p><br /></p>
            <p>
              Bei der Zurückweisung einer Bewerbung löschen wir die uns übermittelten Daten automatisch zwei Monate nach der Bekanntgabe der Zurückweisung. Die Löschung erfolgt
              jedoch nicht, wenn die Daten aufgrund gesetzlicher Bestimmungen, bspw. wegen der Beweispflichten nach dem AGG, eine längere Speicherung von bis zu vier Monaten oder
              bis zum Abschluss eines gerichtlichen Verfahrens erfordern.
            </p>
            <p><br /></p>
            <p>
              Rechtsgrundlage ist in diesem Fall Art. 6 Abs. 1 lit. f) DSGVO und § 24 Abs. 1 Nr. 2 BDSG. Unser berechtigtes Interesse liegt in der Rechtsverteidigung bzw.
              -durchsetzung.
            </p>
            <p><br /></p>
            <p>
              Sofern Sie ausdrücklich in eine längere Speicherung Ihrer Daten einwilligen, bspw. für Ihre Aufnahme in eine Bewerber- oder Interessentendatenbank, werden die Daten
              aufgrund Ihrer Einwilligung weiterverarbeitet. Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. a) DSGVO. Ihre Einwilligung können Sie aber natürlich jederzeit nach Art. 7
              Abs. 3 DSGVO durch Erklärung uns gegenüber mit Wirkung für die Zukunft widerrufen.
            </p>
            <p></p>
            <br />
            <h2 class="n">Hinweise zur Datenverarbeitung im Zusammenhang mit Google Analytics</h2>
            <p class="n">
              Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Ireland Limited. Wenn der Verantwortliche für die Datenverarbeitung auf dieser Website
              außerhalb des Europäischen Wirtschaftsraumes oder der Schweiz sitzt, dann erfolgt die Google Analytics Datenverarbeitung durch Google LLC. Google LLC und Google
              Ireland Limited werden nachfolgend "Google" genannt.<br /><br />Google Analytics verwendet sog. "Cookies", Textdateien, die auf dem Computer des Seitenbesuchers
              gespeichert werden und die eine Analyse der Benutzung der Website durch den Seitenbesucher ermöglichen. Die durch das Cookie erzeugten Informationen über die
              Benutzung dieser Website durch den Seitenbesucher (einschließlich der gekürzten IP-Adresse) werden in der Regel an einen Server von Google übertragen und dort
              gespeichert.<br /><br />Google Analytics wird ausschließlich mit der Erweiterung "_anonymizeIp()" auf dieser Website verwendet. Diese Erweiterung stellt eine
              Anonymisierung der IP-Adresse durch Kürzung sicher und schließt eine direkte Personenbeziehbarkeit aus. Durch die Erweiterung wird die IP-Adresse von Google innerhalb
              von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
              wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Die im Rahmen von Google Analytics von dem entsprechenden Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.<br /><br />Im Auftrag des Seitenbetreibers wird Google die anfallenden Informationen
              benutzen, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
              Internetnutzung verbundene Dienstleistungen dem Seitenbetreiber gegenüber zu erbringen (Art. 6 Abs. 1 lit. f DSGVO). Das berechtigte Interesse an der
              Datenverarbeitung liegt in der Optimierung dieser Website, der Analyse der Benutzung der Website und der Anpassung der Inhalte. Die Interessen der Nutzer werden durch
              die Pseudonymisierung hinreichend gewahrt.<br /><br />Google LLC. bietet eine Garantie auf Basis der Standardvertragsklauseln ein angemessenes Datenschutzniveau
              einzuhalten. Die gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs verknüpften Daten werden nach 50 Monaten automatisch gelöscht. Die
              Löschung von Daten, deren Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.<br /><br />Die Erfassung durch Google Analytics kann verhindert
              werden, indem der Seitenbesucher die Cookie-Einstellungen für diese Website anpasst. Der Erfassung und Speicherung der IP-Adresse und der durch Cookies erzeugten
              Daten kann außerdem jederzeit mit Wirkung für die Zukunft widersprochen werden. Das entsprechende Browser- Plugin kann unter dem folgenden Link heruntergeladen und
              installiert werden: <a href="http://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.<br /><br /><span
                class="jtpl-ga-opt-out-text"
                >Der Seitenbesucher kann die Erfassung durch Google Analytics auf dieser Webseite verhindern, indem er auf folgenden
                <a href='javascript: gaOptOut(["UA-24230794-20"]);' class="ga-opt-out-link">Link</a> klickt. Es wird ein Opt-Out-Cookie gesetzt, der die zukünftige Erfassung der
                Daten beim Besuch dieser Website verhindert.</span
              ><span class="jtpl-ga-opt-out-notification hidden">Der Seitenbesucher hat der Erfassung von Daten mittels Google Analytics auf dieser Webseite widersprochen.</span
              ><br /><br />Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, finden sich in der Datenschutzerklärung von Google (<a
                href="https://policies.google.com/privacy"
                target="_blank"
                >https://policies.google.com/privacy</a
              >) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google (<a href="https://adssettings.google.com/authenticated" target="_blank"
                >https://adssettings.google.com/authenticated</a
              >).
            </p>
            <br />
            <h2 class="n">reCAPTCHA</h2>
            <p class="n">
              Zum Schutz Ihrer Anfragen per Internetformular verwenden wir den Dienst reCAPTCHA des Unternehmens Google LLC (Google). Die Abfrage dient der Unterscheidung, ob die
              Eingabe durch einen Menschen oder missbräuchlich durch automatisierte, maschinelle Verarbeitung erfolgt. Die Abfrage schließt den Versand der IP-Adresse und ggf.
              weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google ein. Zu diesem Zweck wird Ihre Eingabe an Google übermittelt und dort weiter verwendet. Ihre
              IP-Adresse wird von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung dieses Dienstes auszuwerten. Die im Rahmen von reCaptcha von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Für diese Daten gelten die abweichenden Datenschutzbestimmungen des Unternehmens
              Google. Weitere Informationen zu den Datenschutzrichtlinien von Google finden Sie unter:
              <a href="https://policies.google.com/privacy?hl=de" target="_blank">https://policies.google.com/privacy?hl=de</a>
            </p>
            <br />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>