<template>
  <div>
    <h3 class="text-left my-3" id="gallerie">{{ ui.gallerie }}</h3>
    <hr class="my-4 mx-3" />
    <b-carousel id="carousel-fade" style="text-shadow: 0px 0px 2px #000" fade indicators img-width="1024" img-height="480">
      <b-carousel-slide caption="Parkplatz" :img-src="require('../assets/back1.jpg')"></b-carousel-slide>
      <b-carousel-slide caption="Einfahrt" :img-src="require('../assets/front1.jpg')"></b-carousel-slide>
      <b-carousel-slide caption="Einfahrt" :img-src="require('../assets/front2.jpg')"></b-carousel-slide>
      <b-carousel-slide caption="Ansicht von oben" :img-src="require('../assets/drone.jpg')"></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  computed: {
    ui: function () {
      return this.$store.state.ui
    }
  },
  name: 'Gallery'
}
</script>

<style>
</style>