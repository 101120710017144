<template>
  <div id="app" class="m-0 p-0">
    <b-navbar toggleable="lg" type="dark" sticky class="custom-bg">
      <b-navbar-brand href="/">
        <div class="brand"><span class="d-none d-lg-inline">🅿 Baden Airpark - </span><span>Günstig parken</span></div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="setLanguage(selectedLanguage === 'de' ? 'fr' : 'de')">{{ selectedLanguage === 'de' ? '🍷 FR' : '🍺 DE' }}</b-nav-item>
          <b-nav-item to="/#info">{{ ui.info }}</b-nav-item>
          <b-nav-item to="/#ablauf">{{ ui.ablauf }}</b-nav-item>
          <b-nav-item to="/#buchung">{{ ui.buchung }}</b-nav-item>
          <b-nav-item to="/#gallerie">{{ ui.gallerie }}</b-nav-item>
          <b-nav-item to="/#anfahrt">{{ ui.anfahrt }}</b-nav-item>
          <!-- <b-nav-item to="/agb">AGB</b-nav-item> -->
          <b-nav-item to="/#kontakt">{{ ui.kontakt }}</b-nav-item>
          <!-- <b-nav-item to="/impressum">Impressum</b-nav-item> -->
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view style="position: relative; padding-bottom: 2.5rem" />
    <div class="text-right" id="footer">
      <router-link to="/dse" class="mr-3 footer">{{ ui.dse }}</router-link>
      <router-link to="/agb" class="mr-3 footer">{{ ui.agb2 }}</router-link>
      <router-link to="/impressum" class="mr-3 footer">{{ ui.impressum }}</router-link
      ><br />
      <span class="mr-3"> &copy; Daniel Bechtle, {{ new Date().getFullYear() }} </span>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  created() {
    document.title = 'FKB Parkplätze'
  },
  async mounted() {
    await this.fetchAll()
  },
  methods: {
    ...mapMutations(['setLanguage']),
    ...mapActions(['fetchAll'])
  },
  computed: {
    ui: function () {
      return this.$store.state.ui
    },
    selectedLanguage: function () {
      return this.$store.state.selectedLanguage
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  background-color: #e9ecef;
}

.custom-bg {
  background-color: #053566 !important;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7) !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
p {
  font-weight: 100;
  opacity: 0.9;
}
.navbar-nav {
  text-transform: uppercase !important;
  // font-weight: bolder !important;
}
.brand {
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-size: 1em !important;
}
#footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-size: 0.7rem;
  a {
    color: #333 !important;
  }
}
</style>
