<template>
  <div>
    <h3 class="text-left my-3" id="info">{{ui.warum}}</h3>
    <hr class="my-4 mx-3" />
    <b-container>
      <b-row class="my-5 text-left">
        <b-col sm="3">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">1.</span>{{ui.vorteil1Header}}</div>
          <p>{{ui.vorteil1Text}}</p>
        </b-col>
        <b-col sm="3">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">2.</span>{{ui.vorteil2Header}}</div>
          <p>
            {{ui.vorteil2Text}}
          </p>
        </b-col>
        <b-col sm="3">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">3.</span>{{ui.vorteil3Header}}</div>
          <p>{{ui.vorteil3Text}}
          </p>
        </b-col>
        <b-col sm="3">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">4.</span>{{ui.vorteil4Header}}</div>
          <p>{{ui.vorteil4Text}}<a href="https://www.casa-daniele.de/lieferkarte/">Casa Daniele</a>.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <h3 class="text-left my-3" id="ablauf">{{ui.ablauf}}</h3>
    <hr class="my-4 mx-3" />
    <p class="text-left">
      {{ui.ablaufText}}
    </p>
    <b-container>
      <b-row class="my-5 text-left">
        <b-col sm="4">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">1.</span>{{ui.reservierung}}</div>
          <p>
            {{ui.reservierungText}}
          </p>
        </b-col>
        <b-col sm="4">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">2.</span>{{ui.bestaetigung}}</div>
          <p>{{ui.bestaetigungText}}</p>
        </b-col>
        <b-col sm="4">
          <div class="ml-3 advantage-header"><span class="advantage-number mr-2">3.</span>{{ui.anreiseTransfer}}</div>
          <p>
            {{ui.anreiseText}}
            <ul>
              <li v-for="l in ui.anreiseListe" v-bind:key="l">{{l}}</li>
            </ul>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'StaticInfo',
  computed: {
    ui() {
      return this.$store.state.ui
    }
  }
}
</script>

<style>
</style>