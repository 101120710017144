import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Imprint from '../views/Imprint.vue'
import Terms from '../views/Terms.vue'
import Dse from '../views/Dse.vue'
import Success from '../views/Success.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/agb',
    component: Terms
  },
  {
    path: '/impressum',
    component: Imprint
  },
  {
    path: '/dse',
    component: Dse
  },
  {
    path: '/order/:id',
    component: Success
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to) {
  if (to.hash) {
    return {
      selector: to.hash,
      behavior: 'smooth',
    }
  }
}
})

export default router
