import Vue from "vue"
import Vuex from "vuex"
import axios from "axios";
Vue.use(Vuex)


const de = require('../data/de.json');
const fr = require('../data/fr.json');

const baseUrl = process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_STAGE + '/'


export default new Vuex.Store({
  state: {
    config: undefined,
    ui: de,
    selectedLanguage: "de",
    languages: {de,fr},
    serverUrl: process.env.VUE_APP_ENDPOINT,
    order: undefined,

    matrix: [],
  },
  mutations: {
    setLanguage(state, language) {
      state.ui = state.languages[language];
      state.selectedLanguage = language
    },
    addLangauge(state, languageName, languageData) {
      state.languages[languageName] = languageData;
    },
    setOrder(state, order) {
      state.order = order;
    },
    setConfig(state, config) {
      state.config = config;
    }
  },
  getters: {
    config(state) { return state.config},
    order(state) { return state.order }
  },
  actions: {
    async fetchAll({
      commit
    }) {
      // const deResponse = await axios.get(baseUrl + "names/de");
      // const frResponse = await axios.get(baseUrl + "names/fr");

      // commit("addLanguage", "de", deResponse.data.names);
      // commit("addLanguage", "de", frResponse.data.names);
      console.log('fetching config')
      commit("setLanguage", "de")
      
      var response = await axios.get(baseUrl + "config");
      console.log('got config', response.data)
      commit('setConfig', response.data);
    }
  }
})