<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h2 class="my-3">Impressum</h2>
          <h5>Angaben gemäß § 5 TMG:</h5>
          <p>{{ config.name }}<br />Carlos Restaurant<br />{{ config.street }}<br />{{ config.city }}</p>
          <h5>Kontakt</h5>
          <p>
            Telefon: {{ config.phone }}<br />E-Mail: {{ config.mail }}<br />Umsatzsteuer-ID: {{ config.umsatzsteuerId }}<br />Umsatzsteuer-Identifikationsnummer gemäß §27 a
            Umsatzsteuergesetz: {{ config.steuer }}
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  computed: {
    config: function () {
      return this.$store.state.config
    }
  }
}
</script>

<style>
</style>